import React from 'react';

function Terms() {
    return (
        <div>
            <div className="w-full bg-cover bg-center bg-no-repeat lg:pt-44 pt-28 lg:pb-24 pb-14 px-12 xl:px-20 bg-gradient-to-br
    from-blue-200 via-purple-200
    to-pink-200">
                <h1 className="mb-8 text-center text-gray-900 font-bold font-manrope leading-tight lg:text-5xl text-3xl"> Terms of Services </h1>
                <p className="text-gray-900 text-lg leading-8 text-center font-normal px-4"> Last updated: November 12, 2024  </p>
            </div>

            <div className='flex flex-col w-full max-w-7xl px-10 md:px-32 py-16 space-y-2'>
                <section>
                    <div className="text-left leading-6">
                        <strong className="text-xl font-bold">TABLE OF CONTENTS</strong>
                    </div>

                    <div className="text-left leading-6 mt-4">
                        {[
                            { href: "#agreement", label: "1. AGREEMENT TO TERMS" },
                            { href: "#ip", label: "2. INTELLECTUAL PROPERTY RIGHTS" },
                            { href: "#userreps", label: "3. USER REPRESENTATIONS" },
                            { href: "#userreg", label: "4. USER REGISTRATION" },
                            { href: "#payment", label: "5. FEES AND PAYMENT" },
                            { href: "#freetrial", label: "6. FREE TRIAL" },
                            { href: "#cancel", label: "7. CANCELLATION" },
                            { href: "#prohibited", label: "8. PROHIBITED ACTIVITIES" },
                            { href: "#ugc", label: "9. USER GENERATED CONTRIBUTIONS" },
                            { href: "#license", label: "10. CONTRIBUTION LICENSE" },
                            { href: "#mobile", label: "11. MOBILE APPLICATION LICENSE" },
                            { href: "#submissions", label: "12. SUBMISSIONS" },
                            { href: "#thirdparty", label: "13. THIRD-PARTY WEBSITES AND CONTENT" },
                            { href: "#usrights", label: "14. U.S. GOVERNMENT RIGHTS" },
                            { href: "#sitemanage", label: "15. SITE MANAGEMENT" },
                            { href: "#privacypolicy1", label: "16. PRIVACY POLICY" },
                            { href: "#copyright1", label: "17. COPYRIGHT INFRINGEMENTS" },
                            { href: "#terms", label: "18. TERM AND TERMINATION" },
                            { href: "#modifications", label: "19. MODIFICATIONS AND INTERRUPTIONS" },
                            { href: "#law", label: "20. GOVERNING LAW" },
                            { href: "#disputes", label: "21. DISPUTE RESOLUTION" },
                            { href: "#corrections", label: "22. CORRECTIONS" },
                            { href: "#disclaimer", label: "23. DISCLAIMER" },
                            { href: "#liability", label: "24. LIMITATIONS OF LIABILITY" },
                            { href: "#indemnification", label: "25. INDEMNIFICATION" },
                            { href: "#userdata", label: "26. USER DATA" },
                            { href: "#electronic", label: "27. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES" },
                            { href: "#california", label: "28. CALIFORNIA USERS AND RESIDENTS" },
                            { href: "#misc", label: "29. MISCELLANEOUS" },
                            { href: "#contact", label: "30. CONTACT US" },
                        ].map((item, index) => (
                            <div key={index} className="leading-6">
                                <a href={item.href} className="text-md text-blue-600 hover:underline">
                                    {item.label}
                                </a>
                            </div>
                        ))}
                    </div>
                </section>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h1 id="agreement" className="text-lg font-bold mb-4">1. AGREEMENT TO TERMS</h1>

                    <p className="text-md mb-4">
                        These <span className="font-semibold">Terms of Service</span> constitute a legally binding agreement made between you,
                        whether personally or on behalf of an entity (“you”) and <span className="font-semibold">AIZENIT TECHNOLOGIES LIMITED</span>,
                        doing business as <span className="font-semibold">Aizenit</span> ("<span className="font-semibold">Aizenit</span>", “we”, “us”, or “our”),
                        concerning your access to and use of the <a href="https://www.aizenit.com" className="text-blue-500 hover:underline" target="_blank" rel="noreferrer">
                            https://www.aizenit.com</a> website, as well as any other media form, channel, mobile website, or mobile application related or connected to it
                        (collectively, the “Site”). We are registered in the <span className="font-semibold">United Kingdom</span> with our registered office at
                        <span className="font-semibold">1-75 Shelton Street, London, Greater London, WC2H 9JQ</span>. By accessing the Site, you agree to these
                        <span className="font-semibold">Terms of Service</span>.
                    </p>

                    <p className="text-md mb-4">
                        If you do not agree with all of these <span className="font-semibold">Terms of Service</span>, you are expressly prohibited from using the Site
                        and must discontinue use immediately.
                    </p>

                    <p className="text-md mb-4">
                        Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated by reference.
                        We reserve the right, at our sole discretion, to make changes or modifications to these <span className="font-semibold">Terms of Service</span>.
                        We will notify you of changes by updating the “Last updated” date, and you waive any right to specific notice of each change. Please ensure
                        you check the applicable Terms every time you use our Site so you understand which Terms apply. Continued use of the Site after any revised
                        <span className="font-semibold">Terms of Service</span> are posted constitutes your acceptance of those changes.
                    </p>

                    <p className="text-md mb-4">
                        The information provided on the Site is not intended for distribution or use by any person or entity in any jurisdiction or country where such
                        distribution or use would be contrary to law or regulation, or which would subject us to any registration requirement within such jurisdiction.
                        Users accessing the Site from other locations do so on their own initiative and are responsible for compliance with local laws.
                    </p>

                    <p className="text-md mb-4">
                        The Site is not tailored to comply with industry-specific regulations (such as HIPAA, FISMA, etc.), so if your interactions are subject to such
                        laws, you may not use this Site. Additionally, you may not use the Site in any way that would violate the Gramm-Leach-Bliley Act (GLBA).
                    </p>

                    <p className="text-md">
                        All users who are minors in their jurisdiction (generally under the age of 18) must have permission from, and be directly supervised by, their
                        parent or guardian to use the Site. If you are a minor, your parent or guardian must read and agree to these <span className="font-semibold">Terms of Service</span>
                        before you use the Site.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 id="ip" className="text-lg font-bold mb-4">2. INTELLECTUAL PROPERTY RIGHTS</h2>

                    <p className="text-md mb-4">
                        Unless otherwise indicated, the Site is our proprietary property. All source code, databases, functionality, software, website designs,
                        audio, video, text, photographs, and graphics on the Site (collectively, the “Content”), as well as the trademarks, service marks, and
                        logos (the “Marks”), are owned or controlled by us or licensed to us. They are protected by copyright and trademark laws,
                        international copyright laws, and other intellectual property and unfair competition laws. The Content and Marks are provided on the
                        Site “AS IS” for your information and personal use only. Except as expressly provided in these <span className="font-semibold">Terms of Service</span>,
                        no part of the Site, Content, or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                        transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose without our prior written permission.
                    </p>

                    <p className="text-md mb-4">
                        Provided you are eligible to use the Site, you are granted a limited license to access and use it. You may download or print a copy of any portion of
                        the Content to which you have properly gained access, solely for personal, non-commercial use. We reserve all rights not expressly granted to you
                        regarding the Site, Content, and Marks.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 id="userreps" className="text-lg font-bold mb-4">3. USER REPRESENTATIONS</h2>

                    <p className="text-md mb-4">By using the Site, you represent and warrant that:</p>

                    <ul className="list-decimal ml-6 text-md mb-4">
                        <li>All registration information you submit will be true, accurate, current, and complete;</li>
                        <li>You will maintain the accuracy of such information and promptly update it as necessary;</li>
                        <li>You have the legal capacity and agree to comply with these <span className="font-semibold">Terms of Service</span>;</li>
                        <li>You are not a minor in the jurisdiction where you reside, or if a minor, you have received parental permission to use the Site;</li>
                        <li>You will not access the Site through automated or non-human means, such as a bot or script;</li>
                        <li>You will not use the Site for any illegal or unauthorized purpose;</li>
                        <li>Your use of the Site will not violate any applicable laws or regulations.</li>
                    </ul>

                    <p className="text-md">
                        If you provide any untrue, inaccurate, not current, or incomplete information, we reserve the right to suspend or terminate
                        your account and refuse any current or future use of the Site (or any portion thereof).
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 id="userreg" className="text-lg font-bold mb-4">4. USER REGISTRATION</h2>

                    <p className="text-md mb-4">
                        You may be required to register with the Site. You agree to keep your password confidential and are responsible for all activities that occur under your account and password.
                        We reserve the right to remove, reclaim, or change any username you select if we determine, at our sole discretion, that it is inappropriate, obscene, or otherwise objectionable.
                    </p>
                </div>

                <div id="payment" className="text-left leading-6 py-6 md:py-8">
                    <h1 className="text-xl font-bold mb-4">5. FEES AND PAYMENT</h1>

                    <p className="text-gray-700 mb-2">We accept the following forms of payment:</p>

                    <ul className="list-disc list-inside ml-4 mb-4">
                        <li>Visa</li>
                        <li>Mastercard</li>
                        <li>American Express</li>
                        <li>Discover</li>
                    </ul>

                    <p className="text-gray-700 mb-4">
                        You may need to purchase or pay a fee to access some of our services. You agree to provide accurate and complete purchase and account information. Please promptly update your account and payment details, including email address, payment method, and card expiration date, to ensure successful transactions and communication. We bill you through an online account for purchases made via the Site. Sales tax will be added as required. Prices are subject to change, and all payments will be processed in <strong>U.S. dollars</strong>.
                    </p>

                    <p className="text-gray-700 mb-4">
                        You agree to pay all charges at the current prices and authorize us to charge your payment provider. If your purchase involves recurring charges, you consent to these charges being applied on a recurring basis without prior approval until you notify us of cancellation.
                    </p>

                    <p className="text-gray-700">
                        We reserve the right to correct any pricing errors and to refuse any order placed through the Site.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 id="freetrial" className="text-lg font-bold mb-4">6. FREE TRIAL</h2>

                    <p className="text-md mb-4">
                        We offer a <strong>30-day free trial</strong> to new users who register with the Site. At the end of the free trial, your account will be charged according to your chosen subscription plan.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 id="cancellation" className="text-lg font-bold mb-4">7. CANCELLATION</h2>

                    <p className="text-md mb-4">
                        All purchases are non-refundable. You can cancel your subscription at any time by logging into your account. Your cancellation will take effect at the end of the current paid term.
                    </p>

                    <p className="text-md">
                        If you are unsatisfied with our services, please email us at <a href="mailto:info@aizenit.com" className="text-indigo-600 hover:underline">info@aizenit.com</a>.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8" id="prohibited">
                    <h2 className="text-xl font-bold mb-4">8. PROHIBITED ACTIVITIES</h2>

                    <p className="text-md text-gray-700 mb-4">
                        You may not access or use the Site for any purpose other than its intended use. The Site may not be used for commercial endeavors except those specifically endorsed or approved by us.
                    </p>

                    <p className="text-md text-gray-700 mb-4">As a user of the Site, you agree not to:</p>

                    <ul className="list-disc list-inside space-y-2 text-md text-gray-700 ml-6 mb-4">
                        <li>Systematically retrieve data or other content to create or compile a collection, compilation, database, or directory without our written permission.</li>
                        <li>Trick, defraud, or mislead us or other users, especially to obtain sensitive information like passwords.</li>
                        <li>Disable or interfere with security features of the Site, including those preventing or restricting use or copying of content.</li>
                        <li>Disparage or harm us or the Site in any way.</li>
                        <li>Use information from the Site to harass, abuse, or harm others.</li>
                        <li>Abuse our support services or submit false reports of abuse or misconduct.</li>
                        <li>Use the Site in violation of any applicable laws or regulations.</li>
                        <li>Engage in unauthorized framing or linking to the Site.</li>
                        <li>Upload or transmit viruses, Trojan horses, or other disruptive material, including excessive spam or abusive practices.</li>
                        <li>Engage in automated use of the system, such as using scripts or data gathering tools.</li>
                        <li>Remove copyright or proprietary rights notices from any content.</li>
                        <li>Impersonate another user or use their username.</li>
                        <li>Upload or transmit any material designed for passive or active information collection, such as cookies or web bugs.</li>
                        <li>Disrupt or burden the Site or its connected networks and services.</li>
                        <li>Harass or threaten our employees or agents providing the Site.</li>
                        <li>Bypass measures designed to prevent or restrict access to the Site.</li>
                        <li>Copy or adapt the Site’s software or code.</li>
                        <li>Decipher, decompile, disassemble, or reverse engineer the Site’s software, except as permitted by law.</li>
                        <li>Use automated systems, such as spiders or robots, to access the Site, except as allowed by standard search engine or browser usage.</li>
                        <li>Use a buying or purchasing agent to make purchases on the Site.</li>
                        <li>Collect usernames or email addresses for unsolicited emails or create accounts under false pretenses.</li>
                        <li>Use the Site to compete with us or for revenue-generating or commercial purposes.</li>
                        <li>Advertise or sell goods and services through the Site.</li>
                        <li>Sell or transfer your profile to others.</li>
                    </ul>
                </div>

                <div className="text-left leading-6 py-6 md:py-8" id="ugc">
                    <h2 className="text-xl font-bold mb-4">9. USER GENERATED CONTRIBUTIONS</h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        The Site may allow you to participate in blogs, message boards, forums, and other interactive features, providing opportunities to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials (collectively, "Contributions"). These Contributions may be visible to other users and third-party websites. Consequently, any Contributions you make may be considered non-confidential and non-proprietary. By creating or making available any Contributions, you represent and warrant that:
                    </p>
                    <ul className="list-disc ml-6 space-y-2 text-md text-gray-700">
                        <li>
                            Your Contributions do not infringe on any third-party rights, including copyright, patent, trademark, trade secret, or moral rights.
                        </li>
                        <li>
                            You have all necessary licenses, rights, consents, releases, and permissions to use and authorize us and other users of the Site to use your Contributions as outlined in these <span className="font-semibold">Terms of Service</span>.
                        </li>
                        <li>
                            You have obtained written consent from every identifiable individual in your Contributions to use their name or likeness as included in your Contributions.
                        </li>
                        <li>
                            Your Contributions are accurate and not misleading.
                        </li>
                        <li>
                            Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                        </li>
                        <li>
                            Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable.
                        </li>
                        <li>
                            Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                        </li>
                        <li>
                            Your Contributions do not harass or threaten any person or promote violence against a specific individual or group.
                        </li>
                        <li>
                            Your Contributions comply with all applicable laws, regulations, and rules.
                        </li>
                        <li>
                            Your Contributions do not violate the privacy or publicity rights of any third party.
                        </li>
                        <li>
                            Your Contributions do not include content that violates laws regarding child pornography or other protections for minors.
                        </li>
                        <li>
                            Your Contributions do not contain offensive comments related to race, national origin, gender, sexual preference, or physical handicap.
                        </li>
                        <li>
                            Your Contributions do not violate these <span className="font-semibold">Terms of Service</span> or any applicable laws or regulations.
                        </li>
                    </ul>
                    <p className="text-md text-gray-700 mt-4">
                        Violating these terms may result in the suspension or termination of your access to the Site.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4">10. CONTRIBUTION LICENSE</h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        By posting your Contributions to any part of the Site, you automatically grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions. This includes your image and voice, for any purpose, including commercial and advertising, and to prepare derivative works or incorporate them into other works. We may grant sublicenses and use these Contributions in any media formats and channels, now known or developed in the future.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        This license extends to the use of your name, company name, franchise name, and any trademarks, service marks, trade names, logos, and personal or commercial images you provide. You waive all moral rights in your Contributions and warrant that no moral rights have been asserted.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        We do not claim ownership of your Contributions. You retain full ownership and intellectual property rights associated with them. We are not liable for any statements or representations made in your Contributions. You are solely responsible for your Contributions and agree to hold us harmless from any related legal actions.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed">
                        We reserve the right, at our sole discretion, to edit, redact, or change any Contributions; re-categorize them to more appropriate locations on the Site; or pre-screen or delete any Contributions at any time for any reason, without notice. We are not obligated to monitor your Contributions.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4">11. MOBILE APPLICATION LICENSE</h2>

                    <h3 className="text-lg font-semibold mb-2">Use License</h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        If you access the Site via a mobile application, we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on devices you own or control. You may use the application solely according to the terms of this license and our <strong>Terms of Service</strong>.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        You must not:
                    </p>
                    <ul className="list-disc ml-6 text-md text-gray-700 leading-relaxed mb-4">
                        <li>Decompile, reverse engineer, disassemble, or attempt to derive the source code of the application, except as permitted by law.</li>
                        <li>Modify, adapt, improve, or create derivative works from the application.</li>
                        <li>Violate any applicable laws or regulations in connection with your use of the application.</li>
                        <li>Remove or obscure any proprietary notices, including copyright or trademark notices.</li>
                        <li>Use the application for commercial purposes or any purpose for which it was not designed.</li>
                        <li>Make the application available on a network where it can be accessed by multiple devices simultaneously.</li>
                        <li>Develop products or services that compete with or substitute for the application.</li>
                        <li>Send automated queries or unsolicited commercial emails.</li>
                        <li>Use any proprietary information or our intellectual property to create applications or devices for use with the application.</li>
                    </ul>

                    <h3 className="text-lg font-semibold mb-2">Apple and Android Devices</h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        If you use a mobile application obtained from the Apple Store or Google Play:
                    </p>
                    <ul className="list-disc ml-6 text-md text-gray-700 leading-relaxed mb-4">
                        <li>The license is non-transferable and limited to use on devices running iOS or Android, according to the App Distributor's terms of service.</li>
                        <li>We are responsible for maintenance and support as specified in these <strong>Terms of Service</strong>. App Distributors are not obligated to provide maintenance or support.</li>
                        <li>If the mobile application fails to conform to a warranty, you may notify the App Distributor, which may refund the purchase price. The App Distributor will have no other warranty obligations.</li>
                        <li>You must not be in a country subject to U.S. government embargo or on any U.S. government list of prohibited parties.</li>
                        <li>You must comply with applicable third-party terms when using the mobile application.</li>
                        <li>The App Distributors are third-party beneficiaries of these <strong>Terms of Service</strong> and can enforce the terms against you.</li>
                    </ul>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="submissions">
                        12. SUBMISSIONS
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        By submitting any questions, comments, suggestions, ideas, feedback, or other information regarding the Site (collectively, "Submissions"), you acknowledge and agree that such Submissions are non-confidential and shall become our sole property. We will have exclusive rights to all intellectual property associated with these Submissions and may use and disseminate them for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        You waive all moral rights to any Submissions and warrant that they are either original to you or that you have the necessary rights to submit them. You agree that there will be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary rights in your Submissions.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="thirdparty">
                        13. THIRD-PARTY WEBSITES AND CONTENT
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        The Site may include links to other websites ("Third-Party Websites") and content such as articles, photos, text, graphics, music, and other materials originating from third parties ("Third-Party Content"). We do not investigate, monitor, or check for accuracy or appropriateness of Third-Party Websites or Third-Party Content. We are not responsible for any content, accuracy, or policies of these third parties.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Inclusion of or linking to Third-Party Websites or Third-Party Content does not imply endorsement by us. If you access Third-Party Websites or use Third-Party Content, you do so at your own risk. Our Terms of Service no longer apply, and you should review the applicable terms and policies, including privacy practices, of the third-party sites. Any transactions or interactions you have with Third-Party Websites are solely between you and the third party. We are not responsible for any issues or damages arising from such transactions or content. You agree to hold us harmless from any harm or losses resulting from Third-Party Content or Third-Party Websites.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="usrights">
                        14. U.S. GOVERNMENT RIGHTS
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        Our services are classified as “commercial items” under Federal Acquisition Regulation (FAR) 2.101. If acquired by or on behalf of an agency outside the Department of Defense (DOD), our services are governed by these <span className="font-semibold">Terms of Service</span> in accordance with FAR 12.212 (for computer software) and FAR 12.211 (for technical data).
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed">
                        If acquired by or on behalf of a DOD agency, our services are subject to these <span className="font-semibold">Terms of Service</span> under Defense Federal Acquisition Regulation (DFARS) 227.7202-3. Additionally, DFARS 252.227-7015 applies to technical data acquired by the DOD. This clause supersedes any other FAR, DFARS, or related provisions addressing government rights in computer software or technical data under these <span className="font-semibold">Terms of Service</span>.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="sitemanage">
                        15. SITE MANAGEMENT
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        We reserve the right, but not the obligation, to:
                    </p>

                    <ul className="list-disc ml-6 text-md text-gray-700 leading-relaxed space-y-2">
                        <li>
                            Monitor the Site for violations of these <span className="font-semibold">Terms of Service</span>.
                        </li>
                        <li>
                            Take appropriate legal action against anyone who, in our sole discretion, violates the law or these <span className="font-semibold">Terms of Service</span>, including, without limitation, reporting such users to law enforcement authorities.
                        </li>
                        <li>
                            Refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof.
                        </li>
                        <li>
                            Remove from the Site or otherwise disable files and content that are excessive in size or burdensome to our systems, in our sole discretion and without notice or liability.
                        </li>
                        <li>
                            Otherwise manage the Site in a manner designed to protect our rights and property and to facilitate its proper functioning.
                        </li>
                    </ul>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="privacypolicy1">
                        16. PRIVACY POLICY
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        We care about data privacy and security. Please review our <strong><a href="http://www.aizenit.com/terms/privacy" target="_blank" rel="noreferrer" className="text-blue-600 hover:underline">Privacy Policy</a></strong>. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these <span className="font-semibold">Terms of Service</span>.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed mt-4">
                        Please be advised that the Site is hosted in the <span className="font-semibold">United Kingdom</span>. If you access the Site from any other region with laws or requirements governing personal data collection, use, or disclosure that differ from the applicable laws in the <span className="font-semibold">United Kingdom</span>, then through your continued use of the Site, you are transferring your data to the <span className="font-semibold">United Kingdom</span>, and you agree to have your data transferred to and processed in the <span className="font-semibold">United Kingdom</span>.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="copyright1">
                        17. COPYRIGHT INFRINGEMENTS
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        We respect the intellectual property rights of others. If you believe that any material on or through the Site infringes upon a copyright you own or control, please notify us immediately using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in your Notification.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed mt-4">
                        Please be aware that under applicable law, you may be held liable for damages if you make material misrepresentations in a Notification. Therefore, if you are uncertain whether the material on or linked to by the Site infringes your copyright, we recommend contacting an attorney before proceeding.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="terms">
                        18. TERM AND TERMINATION
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed">
                        These <strong>Terms of Service</strong> will remain in effect while you use the Site. We reserve the right, at our sole discretion and without notice or liability, to deny access to and use of the Site (including blocking certain IP addresses) to anyone for any reason or no reason, including for breach of any representation, warranty, or covenant contained in these <strong>Terms of Service</strong> or any applicable law or regulation.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed mt-4">
                        We may terminate your use or participation in the Site or delete your account and any content or information you have posted at any time, without warning and at our sole discretion.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed mt-4">
                        If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you are acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including pursuing civil, criminal, and injunctive relief.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="modifications">
                        19. MODIFICATIONS AND INTERRUPTIONS
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        We reserve the right to change, modify, or remove any content on the Site at our sole discretion and without notice. We are not obligated to update any information on our Site. Additionally, we may modify or discontinue all or part of the Site without prior notice. We are not liable for any changes, price adjustments, suspensions, or discontinuations of the Site.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed">
                        We cannot guarantee that the Site will always be available. Interruptions, delays, or errors may occur due to hardware, software, or other issues, including maintenance. We reserve the right to revise, update, suspend, or discontinue the Site at any time or for any reason, with or without notice. You agree that we are not liable for any loss, damage, or inconvenience caused by your inability to access or use the Site during such periods. Nothing in these <strong>Terms of Service</strong> obligates us to maintain or support the Site or provide any corrections, updates, or releases.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="law">
                        20. GOVERNING LAW
                    </h2>

                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        These terms are governed by and interpreted according to the laws of the <strong>United Kingdom</strong>. The use of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. If you reside in the EU and are a consumer, you are entitled to additional protections provided by the mandatory provisions of your local law.
                    </p>

                    <p className="text-md text-gray-700 leading-relaxed">
                        Both <strong>AIZENIT TECHNOLOGIES LIMITED</strong> and you agree to submit to the non-exclusive jurisdiction of the courts of <strong>[Your Local Jurisdiction]</strong>. This means you can file a claim to enforce your consumer protection rights either in the United Kingdom or in the EU country where you reside.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="disputes">
                        21. DISPUTE RESOLUTION
                    </h2>

                    <h3 className="text-lg font-semibold mb-4">
                        Informal Negotiations
                    </h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        To expedite resolution and control the cost of any dispute, controversy, or claim related to these <strong>Terms of Service</strong> (each a "Dispute" and collectively, the “Disputes”), the Parties agree to first attempt to resolve any Dispute informally for at least 180 days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other.
                    </p>

                    <h3 className="text-lg font-semibold mb-4">
                        Binding Arbitration
                    </h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Any Dispute arising from the relationships between the Parties shall be determined by one arbitrator, chosen in accordance with the Arbitration and Internal Rules of the European Court of Arbitration, which are in force at the time the application for arbitration is filed. The seat of arbitration shall be <strong>London, United Kingdom</strong>, and the proceedings shall be conducted in <strong>English</strong>. The applicable substantive law shall be the law of the <strong>United Kingdom</strong>.
                    </p>

                    <h3 className="text-lg font-semibold mb-4">
                        Restrictions
                    </h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the fullest extent permitted by law:
                        <ul className="list-disc list-inside mb-4">
                            <li>No arbitration shall be joined with any other proceeding;</li>
                            <li>There is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures;</li>
                            <li>There is no right or authority for any Dispute to be brought in a representative capacity on behalf of the general public or any other persons.</li>
                        </ul>
                    </p>

                    <h3 className="text-lg font-semibold mb-4">
                        Exceptions to Informal Negotiations and Arbitration
                    </h3>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        The following Disputes are not subject to the provisions concerning informal negotiations and binding arbitration:
                        <ul className="list-disc list-inside mb-4">
                            <li>Disputes seeking to enforce or protect, or concerning the validity of, any intellectual property rights of a Party;</li>
                            <li>Disputes related to allegations of theft, piracy, invasion of privacy, or unauthorized use;</li>
                            <li>Claims for injunctive relief.</li>
                        </ul>
                        If any part of this provision is found to be illegal or unenforceable, the affected Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction, and the Parties agree to submit to the personal jurisdiction of that court.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="corrections">
                        22. CORRECTIONS
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed">
                        The Site may contain typographical errors, inaccuracies, or omissions, including in descriptions, pricing, availability, and other information. We reserve the right to correct any such errors, inaccuracies, or omissions and to update or modify the information on the Site at any time without prior notice.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="disclaimer">
                        23. DISCLAIMER
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed">
                        The Site is provided on an "as-is" and "as-available" basis. Your use of the Site and our services is at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee the accuracy or completeness of the Site’s content or any content linked to the Site. We are not liable for:
                    </p>
                    <ul className="list-disc list-inside text-md text-gray-700 leading-relaxed mb-4">
                        <li>Errors, mistakes, or inaccuracies in content and materials.</li>
                        <li>Personal injury or property damage resulting from your access to or use of the Site.</li>
                        <li>Unauthorized access to or use of our secure servers and any personal or financial information stored therein.</li>
                        <li>Interruption or cessation of transmission to or from the Site.</li>
                        <li>Bugs, viruses, Trojan horses, or the like that may be transmitted through the Site by third parties.</li>
                        <li>Errors or omissions in any content and materials or any loss or damage incurred from the use of content posted or transmitted via the Site.</li>
                    </ul>
                    <p className="text-md text-gray-700 leading-relaxed">
                        We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by third parties through the Site, any linked website, or any website or mobile application featured in advertisements. We are not liable for monitoring transactions between you and third-party providers of products or services. Exercise caution and use your best judgment when engaging with third-party products or services.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="liability">
                        24. LIMITATIONS OF LIABILITY
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        In no event will we, our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including but not limited to lost profits, lost revenue, or loss of data, arising from your use of the Site, even if we have been advised of the possibility of such damages.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Notwithstanding anything to the contrary contained herein, our liability to you for any cause whatsoever and regardless of the form of the action will at all times be limited to the amount paid by you to us, if any, during the one (1) month period prior to any cause of action arising.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Certain U.S. state laws and international laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers or limitations may not apply, and you may have additional rights.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="indemnification">
                        25. INDEMNIFICATION
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        You agree to defend, indemnify, and hold us, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, harmless from any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
                    </p>
                    <ul className="list-disc ml-6 mb-4">
                        <li className="text-md text-gray-700 leading-relaxed">Your Contributions;</li>
                        <li className="text-md text-gray-700 leading-relaxed">Your use of the Site;</li>
                        <li className="text-md text-gray-700 leading-relaxed">Breach of these Terms of Service;</li>
                        <li className="text-md text-gray-700 leading-relaxed">Any breach of your representations and warranties set forth in these Terms of Service;</li>
                        <li className="text-md text-gray-700 leading-relaxed">Your violation of the rights of a third party, including but not limited to intellectual property rights; or</li>
                        <li className="text-md text-gray-700 leading-relaxed">Any overt harmful act toward any other user of the Site with whom you connected via the Site.</li>
                    </ul>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        Notwithstanding the foregoing, we reserve the right to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of such claims at your expense. We will use reasonable efforts to notify you of any claim, action, or proceeding subject to this indemnification upon becoming aware of it.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="userdata">
                        26. USER DATA
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        We will maintain certain data that you transmit to the Site for managing its performance, as well as data related to your use of the Site. While we perform routine backups of data, you are solely responsible for all data you transmit or that pertains to your activities on the Site.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        You agree that we are not liable for any loss or corruption of such data, and you waive any right to take action against us arising from such loss or corruption.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="electronic">
                        27. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        By visiting the Site, sending us emails, and completing online forms, you engage in electronic communications. You consent to receive electronic communications and agree that all agreements, notices, disclosures, and other communications we provide electronically, via email, and on the Site, meet any legal requirement for written communication.
                    </p>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        You agree to the use of electronic signatures, contracts, orders, and other records, as well as the electronic delivery of notices, policies, and transaction records initiated or completed by us or through the Site. You waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws that require an original signature or non-electronic records, or that mandate payments or credits by means other than electronic.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="california">
                        28. CALIFORNIA USERS AND RESIDENTS
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        If you are a California resident and have a complaint that is not satisfactorily resolved, you may contact the Complaint Assistance Unit of the Division of Consumer Services at the California Department of Consumer Affairs. You can reach them in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834, or by telephone at (800) 952-5210 or (916) 445-1254.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="misc">
                        29. MISCELLANEOUS
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        These <strong>Terms of Service</strong> and any policies or operating rules posted by us on the Site constitute the entire agreement between you and us. Our failure to exercise or enforce any right or provision of these <strong>Terms of Service</strong> does not waive such right or provision. We may assign our rights and obligations at any time. We are not liable for any loss, damage, delay, or failure to act due to causes beyond our control. If any provision of these <strong>Terms of Service</strong> is found to be unlawful, void, or unenforceable, it will be severed, and the remaining provisions will remain valid and enforceable. No joint venture, partnership, employment, or agency relationship is created by these <strong>Terms of Service</strong> or your use of the Site. These <strong>Terms of Service</strong> will not be construed against us because we drafted them. You waive any defenses based on the electronic form of these <strong>Terms of Service</strong> and the lack of physical signatures.
                    </p>
                </div>

                <div className="text-left leading-6 py-6 md:py-8">
                    <h2 className="text-xl font-bold mb-4" id="contact">
                        30. CONTACT US
                    </h2>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        To resolve a complaint regarding the Site or to receive further information, please contact us at:
                    </p>
                    <address className="text-md text-gray-700 leading-relaxed mb-4">
                        <strong>Aizenit Technologies Limited</strong><br />
                        1-75 Shelton Street<br />
                        London, Greater London<br />
                        WC2H 9JQ<br />
                        United Kingdom
                    </address>
                    <p className="text-md text-gray-700 leading-relaxed mb-4">
                        <strong>Email:</strong> <a href="mailto:info@aizenit.com" className="text-blue-600 hover:underline">info@aizenit.com</a>
                    </p>
                </div>

            </div>
        </div>
    )
}
export default Terms;