import SecurityOne from '../assets/images/aizenit-security/Security-01.svg'
import SecurityTwo from '../assets/images/aizenit-security/Security-02.svg'
import SecurityThree from '../assets/images/aizenit-security/Security-03.svg'
import './security.css';

function Security() {
    return (
        <div className=''>
            <div class="w-full bg-cover bg-center bg-no-repeat lg:pt-44 pt-28 lg:pb-24 pb-14 px-12 xl:px-20 bg-gradient-to-br
    from-blue-200 via-purple-200
    to-pink-200">
                <h1 class="mb-8 text-center text-gray-900 font-bold font-manrope leading-tight lg:text-5xl text-3xl">Security at Stashify </h1>
                <p class="text-gray-900 text-lg leading-8 text-center font-normal px-4"> How Stashify Protects Your Data  </p>
            </div>
            <div className='flex flex-col justify-center items-center mt-20'>
                <section className="relative pb-20">
                    <div className="flex-1 order-1 xl:hidden md:hidden items-center justify-center mb-8 md:mb-0">
                        <img className="w-full md:w-96 h-auto" src={SecurityOne} alt="Security at Aizenit" />
                    </div>
                    <div className="flex md:flex-row">
                        <div className="flex-1 order-2 md:order-1 flex flex-col">
                            <h2 className="text-black text-4xl font-bold mb-5">Cloud Security</h2>
                            <h4 className="text-2xl font-semibold">Data Center Physical Security</h4>
                            <div className="mb-4">
                                <ul className='list-disc ml-5 md:ml-10 text-md'>
                                    <li>We deploy on Azure, AWS, and GCP, ensuring internal<br /> compliance with ISO 27001 and SOC standards.</li>
                                    <li>Our providers implement robust controls including backup power,<br /> fire suppression, and secure device destruction.</li>
                                    <li>Exclusive partnership with trusted and secure payment gateways<br /> such as Stripe, Wise, Instamojo, and Razorpay for bank connections.</li>
                                    <li>With restricted API keys, access to your account is tightly controlled,<br /> permitting only authorized actions.</li>
                                    <li>Integration with third-party accounting software uses industry-standard<br /> encryption for data transmission and follows strict authentication practices like OAuth.</li>
                                </ul>
                            </div>
                            <h4 className="pt-4 text-2xl font-semibold">On-Site Security</h4>
                            <div className="mb-4">
                                <ul className='list-disc ml-5 md:ml-10'>
                                    <li>Layered physical security with security guards, fencing, video monitoring, and intrusion detection.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex-1 order-1 hidden md:flex flex items-center justify-center mb-8 md:mb-0">
                            <img className="w-full md:w-96 h-auto" src={SecurityOne} alt="Security at Aizenit" />
                        </div>
                    </div>
                </section>

                <section className="relative pt-10 mt-10">
                <div className="flex-1  order-1  flex xl:hidden md:hidden flex items-start justify-start">
                            <img className="w-full md:w-96 h-auto" src={SecurityTwo} alt="Security at Aizenit" />
                        </div>
                    <div className="flex flex-row justify-start items-start">
                        <div className="flex-1   hidden md:flex flex items-start justify-start">
                            <img className="w-full md:w-96 h-auto" src={SecurityTwo} alt="Security at Aizenit" />
                        </div>
                        <div className="flex-1">
                            <h2 className="text-black text-4xl font-bold mb-5">Network Security</h2>
                            <h4 className="text-2xl font-semibold">Security Team</h4>
                            <div className="mb-4">
                                <ul className='list-disc ml-5 md:ml-10'>
                                    <li>Our in-house team responds to security alerts and conducts regular third-party penetration tests.</li>
                                </ul>
                            </div>
                            <h4 className="text-2xl font-semibold">Threat Detection and Vulnerability Scanning</h4>
                            <div className="mb-4">
                                <ul className='list-disc ml-5 md:ml-10'>
                                    <li>Continuous monitoring for unauthorized activities and regular internal scans to address vulnerabilities.</li>
                                </ul>
                            </div>
                            <h4 className="text-2xl font-semibold">Access Control</h4>
                            <div className="mb-4">
                                <ul className='list-disc ml-5 md:ml-10'>
                                    <li>Access is restricted by the least privilege model, with frequent audits and 2FA for all production systems.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <div className='flex flex-col mt-20 md:mt-32'>
                    <section className="pb-4">
                        <h2 className="text-black text-4xl font-semibold mb-5">Encryption</h2>
                        <h4 className="text-2xl font-semibold">In Transit</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Data communication is encrypted with TLS 1.2 or higher.</li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-semibold">At Rest</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Data is encrypted using AES-256 encryption.</li>
                            </ul>
                        </div>
                    </section>
                    <section className="pb-4 mt-10">
                        <h2 className="text-black text-4xl font-semibold mb-5">Availability & Continuity</h2>
                        <h4 className="text-2xl font-semibold">Uptime</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Services are deployed across multiple availability zones for reliability and scalability.</li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-semibold">Disaster Recovery</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Comprehensive disaster recovery plans ensure service availability during major outages.</li>
                            </ul>
                        </div>
                    </section>
                    <section className="pb-4 mt-10">
                        <h2 className="text-black text-4xl font-semibold mb-5">Application Security</h2>
                        <h4 className="text-2xl font-semibold">Quality Assurance</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Code reviews and testing by our QA team, with a focus on identifying and fixing security vulnerabilities.</li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-semibold">Environment Segregation</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Separation of testing, staging, and production environments to protect customer data.</li>
                            </ul>
                        </div>
                    </section>
                    <section className="pb-4 mt-10">
                        <div className="flex flex-col md:flex-row items-start">
                            <div className="flex-1 pr-0 md:pr-10">
                                <h2 className="text-black text-4xl font-semibold mb-5">Personal Security</h2>
                                <h4 className="text-2xl font-semibold">Security Awareness</h4>
                                <div className="mb-4">
                                    <ul className='list-disc ml-5 md:ml-10'>
                                        <li>Regular training for all employees on security practices and policies.</li>
                                    </ul>
                                </div>
                                <h4 className="text-2xl font-semibold">Access Controls</h4>
                                <div className="mb-4">
                                    <ul className='list-disc ml-5 md:ml-10'>
                                        <li>Documented and approved access processes, with two-factor authentication for critical systems.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex-1 flex items-end justify-end">
                                <img className="w-full md:w-96 h-auto" src={SecurityThree} alt="Security at Aizenit" />
                            </div>
                        </div>
                    </section>
                    <section className="pb-4 mt-10 mb-10">
                        <h2 className="text-black text-4xl font-semibold mb-5">Data Privacy</h2>
                        <h4 className="text-2xl font-semibold">GDPR Compliance</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Find Stashify GDPR statement <a href="/gdpr-statement" className="text-blue-500">here.</a></li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-semibold">Privacy Policy</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Details on data handling can be found in our privacy policy. Contact our DPO at <a href="mailto:dpo@stashify.com" className="text-blue-500">dpo@stashify.com</a> for more information.</li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-bold">Vendor Management</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>Rigorous vendor assessment and monitoring to ensure security compliance.</li>
                            </ul>
                        </div>
                        <h4 className="text-2xl font-semibold">Responsible Disclosure</h4>
                        <div className="mb-4">
                            <ul className='list-disc ml-5 md:ml-10'>
                                <li>We encourage responsible disclosure of vulnerabilities. Please email <a href="mailto:security@stashify.com" className="text-blue-500">security@stashify.com</a> with details of any potential issues.</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Security;
