import logo from "../assets/images/Stashify-logo.png"
import aizenit from "../assets/images/aizenit-logo-black.webp"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";

function Footer() {
    return (<div className="w-full flex items-center justify-center ">
        <div className="flex flex-col w-full max-w-7xl px-4 sm:px-6 lg:px-8 ">
            <div className="py-6">
                <a href="https://www.stashify.ai/" className="flex
            justify-center lg:justify-start">
                    <img
                        src={logo}
                        width="200px" height="auto" alt="logo"
                        loading="lazy" />
                </a>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3
                md:gap-8 py-4 max-sm:max-w-sm max-sm:mx-auto gap-y-8">
                <div className="col-span-full mb-10 lg:col-span-2 lg:mb-0">
                    <div className="flex flex-col
                        text-sm
                        text-gray-500
                        lg:max-w-xs
                        text-center
                        lg:text-left">
                        <p className="
                            font-semibold
                            uppercase
                            mb-4 text-2xl">Get In Touch</p>
                        <a
                            href="tel:+442035403828"
                            className="text-decoration-none
                            font-light">(+44)
                            20
                            3540 3828</a>
                        <a
                            href="mailto:info@stashify.com"
                            className="text-decoration-none
                            font-light">info@stashify.com</a>
                        <div className="mb-4">
                            <p style={{ fontSize: "13px  !important" }}>
                                71-75 Shelton Street,
                                <br />London,
                                <br />Greater London,
                                <br />United Kingdom,
                                <br />WC2H 9JQ.
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="lg:mx-auto
                    text-left
                    ">
                    <h4
                        className="text-lg
                        text-gray-900
                        font-medium
                        mb-7">Stashify</h4>
                    <ul
                        className="text-sm
                        transition-all
                        duration-500">
                        <li
                            className="mb-3 md:mb-6"><a
                                href="/"
                                className="text-gray-600
                                hover:text-gray-900">Home</a></li>
                        <li
                            className="mb-3 md:mb-6"><a
                                href="#pricing"
                                className="
                                text-gray-600
                                hover:text-gray-900">Pricing</a></li>
                        <li><a
                            href="#contact"
                            className="
                                text-gray-600
                                hover:text-gray-900">Contact Us</a></li>
                    </ul>
                </div>
                <div
                    className="lg:mx-auto
                    text-left">
                    <h4
                        className="text-lg
                        text-gray-900
                        font-medium
                        mb-7">Company</h4>
                    <ul
                        className="text-sm
                        transition-all
                        duration-500">
                        <li
                            className="mb-3 md:mb-6"><a
                                href="/#contact"
                                target="_blank"  rel="noreferrer"
                                className="
                                text-gray-600
                                hover:text-gray-900">Partnership</a></li>
                        <li
                            className="mb-3 md:mb-6"><a
                                href="/security"
                                className="
                                text-gray-600
                                hover:text-gray-900">Security</a></li>
                        <li
                            className="mb-3 md:mb-6"><a
                                href="https://www.aizenit.com/gdpr-statement"
                                target="_blank"  rel="noreferrer"
                                className="
                                text-gray-600
                                hover:text-gray-900">GDPR Statement</a></li>
                    </ul>
                </div>
                <div
                    className="lg:mx-auto
                    text-left">
                    <h4
                        className="text-lg
                        text-gray-900
                        font-medium
                        mb-7">Resources</h4>
                    <ul
                        className="text-sm
                        transition-all
                        duration-500">
                        <li
                            className="mb-3 md:mb-6"><a
                                href="https://blog.aizenit.com/series/case-studies"
                                target="_blank"  rel="noreferrer"
                                className="
                                text-gray-600
                                hover:text-gray-900"> Case Studies</a></li>
                        <li
                            className="mb-3 md:mb-6"><a
                                href="/privacy"
                                className="
                                text-gray-600
                                hover:text-gray-900">Privacy
                                Policy</a></li>
                        <li
                            className="mb-3 md:mb-6"><a
                                href="/terms-of-service"
                                className="
                                text-gray-600
                                hover:text-gray-900">Terms of Service</a></li>
                    </ul>
                </div>

            </div>
            <div
                className="py-7
                border-t
                border-gray-200">
                <div
                    className="flex
                    items-center
                    justify-center
                    flex-col
                    lg:justify-between
                    lg:flex-row">
                    <div className="">
                        <img
                            src={aizenit}
                            width="80px" height="auto"
                            alt="logo"
                            className="py-2"
                            loading="lazy" />
                        <div className="flex flex-col
                                align-items-center space-y-1">
                            <div className="mb-0 me-3">
                                Fully owned by Aizenit Technologies
                                Limited. &copy; 2024 All Rights
                                Reserved</div>
                            <span className="border-end border-1
                                    border-secondary h-50"></span>
                            <p style={{
                                fontSize: "12px !important",
                                lineHeight: "17px"
                            }}>
                                AIZENIT registered in England and Wales
                                under
                                the company registration number
                                13108663.<br />
                                Registered Office address: 71-75 Shelton
                                Street,
                                London, Greater London, United Kingdom,
                                WC2H
                                9JQ.
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex
                        mt-4
                        space-x-4
                        sm:justify-center
                        lg:mt-0
                        ">
                        <a
                            href="https://www.facebook.com/aizenit/"
                            className="w-9
                            h-9
                            rounded-full
                            flex
                            justify-center
                            items-center">

                            <FontAwesomeIcon icon={faFacebook} size="xl" color='#105ce0' />
                        </a>

                        <a
                            href="https://twitter.com/AizenitTech"
                            className="w-9
                                    h-9
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center">

                            <FontAwesomeIcon icon={faTwitter} size="xl" color='#105ce0' />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/aizenit/mycompany/"
                            className="w-9
                                    h-9
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center ">

                            <FontAwesomeIcon icon={faLinkedinIn} size="xl" color='#105ce0' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}


export default Footer;