function Privacy() {
    return (
        <div>
            <div className="w-full bg-cover bg-center bg-no-repeat lg:pt-44 pt-28 lg:pb-24 pb-14 px-12 xl:px-20 bg-gradient-to-br
    from-blue-200 via-purple-200
    to-pink-200">
                <h1 className="mb-8 text-center text-gray-900 font-bold font-manrope leading-tight lg:text-5xl text-3xl">Privacy Notice </h1>
                {/* <p className="text-gray-900 text-lg leading-8 text-center font-normal px-4"> Last updated: November 12, 2024  </p> */}
            </div>

            <section class="relative min-h-screen max-w-7xl items-center justify-center pt-16 pb-16 w-full">
                <div class="px-10 md:px-8 lg:px-16 xl:px-36 flex flex-col w-full">
                    <div className="space-y-6">
                        <div className="text-black font-manrope">
                            <div className="mb-6">
                                <p className="font-bold text-xl">
                                    For us data security comes first - We'll never sell or share your data. For more details, here is our Privacy Policy.
                                </p>
                            </div>
                            <div className="text-gray-700 text-lg leading-6">
                                <span className="text-gray-600 text-lg">
                                    This privacy notice for <span className="font-bold">Stashify Technologies</span> (also referred to as '<strong>we</strong>', '<strong>us</strong>', or '<strong>our</strong>'), describes how and why we might collect, store, use, and/or share ('<strong>process</strong>') your information when you use our services ('<strong>Services</strong>'), such as when you:
                                </span>
                            </div>
                            <ul className="list-disc ml-5 mt-2">
                                <li className="leading-6 text-gray-600 text-lg">
                                    Visit our website at <a href="https://www.Stashify.com" target="_blank" rel="noopener noreferrer" className="text-blue-700">https://www.Stashify.com</a> or any website of ours that links to this privacy notice
                                </li>
                            </ul>
                            <ul className="list-disc ml-5">
                                <li className="leading-6 text-gray-600 text-lg">
                                    Download and use our mobile application (Genieaz) or any other application of ours that links to this privacy notice
                                </li>
                            </ul>
                            <ul className="list-disc ml-5">
                                <li className="leading-6 text-gray-600 text-lg">
                                    Engage with us in other related ways, including any sales, marketing, or events
                                </li>
                            </ul>
                        </div>
                        <div className="leading-6 text-lg text-gray-600 mt-4">
                            <strong>Questions or concerns? </strong>
                            {/* <br /> */}
                            <br />
                            Reading this privacy notice will help you understand your privacy rights and choices. If
                            you do not agree with our policies and practices, please do not use our Services. If you
                            still have any questions or concerns, please contact us at <span className="font-semibold">info@Stashify.com</span>.
                        </div>


                        <div className="leading-6 text-lg font-semibold">
                            <strong>SUMMARY OF KEY POINTS</strong>
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                <em>
                                    This summary provides key points from our privacy notice, but you can find out more
                                    details about any of these topics by clicking the link following each key point or by
                                    using our table of contents below to find the section you are looking for. You can
                                    also click&nbsp;
                                </em>
                            </strong>
                            {/* <Link to="./#toc" className="text-blue-600 hover:underline">
                                                <strong>
                                                    <em>here</em>
                                                </strong>
                                            </Link> */}
                            <span>
                                <strong>
                                    <em>&nbsp;to go directly to our table of contents.</em>
                                </strong>
                            </span>
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                What personal information do we process?
                            </strong>
                            <br />
                            When you visit, use, or navigate our Services, we may process personal information depending on how you interact with
                            <span className="font-semibold">Stashify Technologies</span> and the Services, the choices you make, and the products
                            and features you use. Click&nbsp;
                            {/* <Link to="./#personalinfo" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                Do we process any sensitive personal information?
                            </strong>
                            <br />
                            We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law.
                            Click&nbsp;
                            {/* <Link to="./#sensitiveinfo" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                Do we receive any information from third parties?
                            </strong>
                            <br />
                            We do not receive any information from third parties.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                How do we process your information?
                            </strong>
                            <br />
                            We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud
                            prevention, and to comply with law. We may also process your information for other purposes with your consent. We process
                            your information only when we have a valid legal reason to do so. Click&nbsp;
                            {/* <Link to="./#infouse" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                In what situations and with which parties do we share personal information?
                            </strong>
                            <br />
                            We may share information in specific situations and with specific third parties. Click&nbsp;
                            {/* <Link to="./#whoshare" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                How do we keep your information safe?
                            </strong>
                            <br />
                            We have organizational and technical processes and procedures in place to protect your personal information. However, no
                            electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we
                            cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat
                            our security and improperly collect, access, steal, or modify your information. Click&nbsp;
                            {/* <Link to="./#infosafe" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                What are your rights?
                            </strong>
                            <br />
                            Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your
                            personal information. Click&nbsp;
                            {/* <Link to="./#privacyrights" className="text-blue-600 hover:underline">
                                                here
                                            </Link> */}
                            &nbsp;to learn more.
                        </div>

                        <div className="leading-6 text-lg text-gray-600">
                            <strong>
                                How do you exercise your rights?
                            </strong>
                            <br />
                            The easiest way to exercise your rights is by filling out our data subject request form available here: <span className="font-semibold">app.genieaz.com</span>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </div>

                        <div class="leading-relaxed text-gray-600">
                            <span class="text-lg">
                                Want to learn more about what Stashify Technologies does with any information we collect? Click
                            </span>
                            <a class="text-blue-600 hover:underline" href="./#toc">
                                <span class="text-lg ml-1">here</span>
                            </a>
                            <span class="text-lg"> to review the notice in full.</span>
                        </div>
                        <div class="leading-relaxed"><br /></div>
                        <div id="toc" class="leading-relaxed">
                            <span class="text-lg text-gray-600"><span class="text-black font-bold">TABLE OF CONTENTS</span></span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#sociallogins">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#inforetain">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#infosafe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#infominors">9. DO WE COLLECT INFORMATION FROM MINORS?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#DNT">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#caresidents">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <a class="text-blue-700 hover:underline" href="#virginia">13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg">
                                <a class="text-blue-700 hover:underline" href="./#policyupdates">14. DO WE MAKE UPDATES TO THIS NOTICE?</a>
                            </span>
                        </div>
                        <div class="leading-relaxed">
                            <a class="text-blue-700 hover:underline" href="./#contact">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                        </div>
                        <div class="leading-relaxed">
                            <a class="text-blue-700 hover:underline" href="./#request">16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
                        </div>

                        <div id="infocollect" class="leading-relaxed">
                            <span class="text-gray-900">
                                <span class="text-gray-900 text-lg font-bold">1. WHAT INFORMATION DO WE COLLECT?</span>
                            </span>
                        </div>

                        <div id="personalinfo" class="leading-relaxed">
                            <span class="text-black text-lg font-bold">Personal information you disclose to us</span>
                        </div>
                        <div>
                            <div class="leading-relaxed">
                                <span class="text-gray-600">
                                    <span class="text-gray-700 text-lg">
                                        <strong><em>In Short:</em></strong>
                                    </span>
                                    <span class="text-gray-700 text-lg"><em>We collect personal information that you provide to us.</em></span>
                                </span>
                            </div>
                        </div>
                        <div class="leading-relaxed">
                            <span class="text-lg text-gray-700">
                                We collect personal information that you voluntarily provide to us when you register on the Services,
                                express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                            </span>
                        </div>

                        <div class="leading-relaxed">
                            <span class="text-lg text-gray-700">
                                <strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                            </span>
                        </div>
                        <ul class="mt-3 mb-0 list-disc pl-5">
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">names</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">phone numbers</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">email addresses</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">job titles</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">usernames</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">passwords</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">mailing addresses</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">contact preferences</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">contact or authentication data</span>
                            </li>
                            <li class="leading-relaxed text-gray-700 text-lg">
                                <span class="text-lg text-gray-700">debit/credit card numbers</span>
                            </li>
                        </ul>

                        <div className="leading-relaxed">
                            <span className="text-lg text-gray-600">
                                <span className="text-lg text-gray-600">
                                    <span data-custom-class="body_text">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text">
                                                <span className="forloop-component"></span>
                                            </span>
                                            <span data-custom-class="body_text">
                                                <span className="statement-end-if-in-editor"></span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>

                        <div id="sensitiveinfo" className="leading-relaxed">
                            <span className="text-lg">
                                <span data-custom-class="body_text">
                                    <strong>Sensitive Information.</strong>
                                    <span className="block-component"></span>
                                    When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:
                                    <span className="forloop-component"></span>
                                </span>
                            </span>
                        </div>

                        <ul className=" list-disc mt-2 pl-5">
                            <li className="leading-relaxed">
                                <span className="text-lg">
                                    <span data-custom-class="body_text">
                                        <span className="question">financial data</span>
                                    </span>
                                </span>
                            </li>
                        </ul>

                        <div>
                            <span className="text-lg">
                                <span data-custom-class="body_text">
                                    <span className="forloop-component"></span>
                                </span>
                                <span data-custom-class="body_text">
                                    <span className="statement-end-if-in-editor"></span>
                                </span>
                                <span className="text-lg text-gray-600">
                                    <span className="text-lg text-gray-600">
                                        <span data-custom-class="body_text">
                                            <span className="text-lg">
                                                <span data-custom-class="body_text">
                                                    <span className="block-component"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>

                        <div className="leading-relaxed">
                            <span className="text-lg text-gray-600">
                                <span data-custom-class="body_text">
                                    <strong>Payment Data.</strong> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by
                                    <span className="forloop-component"></span>
                                    <span className="text-lg text-gray-600">
                                        <span data-custom-class="body_text">
                                            <span className="text-lg">
                                                <span data-custom-class="body_text">
                                                    <span className="block-component"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                                <span className="question">__________</span>
                                <span className="text-lg text-gray-600">
                                    <span className="text-lg text-gray-600">
                                        <span data-custom-class="body_text">
                                            <span className="text-lg">
                                                <span data-custom-class="body_text">
                                                    <span className="block-component"></span>
                                                </span>
                                            </span>
                                        </span>
                                        <span className="forloop-component"></span>
                                        <span className="text-lg">
                                            <span data-custom-class="body_text">.</span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>

                        <div className="leading-relaxed">
                            <ul className="list-disc mt-2 pl-5">
                                <li>
                                    <span data-custom-class="body_text">
                                        <span className="text-lg text-gray-700">
                                            <strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
                                            <span className="block-component"></span>
                                        </span>
                                    </span>
                                </li>
                            </ul>

                            <div className="leading-relaxed">
                                <span className="block-component">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text"></span>
                                    </span>
                                </span>
                            </div>

                            <ul className="list-disc mt-2 pl-5">
                                <li className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        <span data-custom-class="body_text">
                                            <em>Mobile Device Access.</em> We may request access or permission to certain features from your mobile device, including your mobile device's
                                            <span className="forloop-component"></span>
                                            <span className="question">sms messages</span>, <span className="forloop-component"></span>
                                            <span className="question">storage</span>, <span className="forloop-component"></span>
                                            and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                                            <span className="statement-end-if-in-editor"></span>
                                        </span>
                                    </span>
                                </li>
                            </ul>

                            <div className="leading-relaxed">
                                <span className="block-component">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text"></span>
                                    </span>
                                </span>
                            </div>

                            <ul className="list-disc mt-2 pl-5">
                                <li className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        <span data-custom-class="body_text">
                                            <em>Mobile Device Data.</em> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
                                            <span className="statement-end-if-in-editor"></span>
                                        </span>
                                    </span>
                                </li>
                            </ul>

                            <div className="leading-relaxed">
                                <span className="block-component">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text"></span>
                                    </span>
                                </span>
                            </div>

                            <ul className="list-disc mt-2 pl-5">
                                <li className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        <span data-custom-class="body_text">
                                            <em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                                            <span className="statement-end-if-in-editor"></span>
                                        </span>
                                    </span>
                                </li>
                            </ul>

                            <div className="leading-relaxed">
                                <span className="text-lg text-gray-700">
                                    <span data-custom-class="body_text">
                                        This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
                                    </span>
                                </span>
                            </div>

                            <div className="leading-relaxed">
                                <br />
                            </div>

                            <div className="leading-relaxed">
                                <span className="statement-end-if-in-editor">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text"></span>
                                    </span>
                                </span>
                            </div>

                            <div className="leading-relaxed">
                                <span className="text-lg text-gray-700">
                                    <span data-custom-class="body_text">
                                        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                                    </span>
                                </span>
                            </div>

                            <div className="leading-relaxed">
                                <span className="text-lg text-gray-600">
                                    <span data-custom-class="body_text">
                                        <span className="text-lg">
                                            <span className="text-lg text-gray-600">
                                                <span className="text-lg text-gray-600">
                                                    <span className="forloop-component"></span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </div>

                            <div className="leading-relaxed">
                                <br />
                            </div>

                        </div>

                        <div className="space-y-4">
                            {/* Block Component */}
                            <div className="leading-relaxed text-gray-600 text-lg">
                                <div className="block-component"></div>
                            </div>

                            {/* Heading */}
                            <div className="leading-relaxed text-black text-lg">
                                <strong>Information automatically collected</strong>
                            </div>

                            {/* Information Section */}
                            <div className="leading-relaxed text-gray-600 text-lg space-y-2">
                                <div>
                                    <div className="leading-relaxed text-gray-600 text-lg">
                                        <p className="text-gray-600 text-lg">
                                            <strong><em>In Short:</em></strong>
                                            Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
                                        </p>
                                    </div>

                                </div>

                                <p className="text-gray-600 text-lg">
                                    We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                                </p>

                                <div className="leading-relaxed text-gray-600 text-lg">
                                    <span className="block-component"></span>
                                </div>

                                <p className="text-gray-600 text-lg">
                                    The information we collect includes:
                                </p>

                                <ul className="list-disc pl-5 text-gray-600 text-lg space-y-1">
                                    <li>
                                        <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports, and hardware settings).
                                    </li>
                                    <li>
                                        <em>Device Data.</em> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                                    </li>
                                    <li>
                                        <em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                                    </li>
                                </ul>

                                {/* Processing Information */}
                                <div id="infouse" className="leading-relaxed text-gray-900 text-lg  pt-6">
                                    <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
                                </div>

                                <div className="leading-relaxed text-gray-600 text-lg">
                                    <strong><em>In Short: </em></strong>
                                    <em>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em>
                                </div>
                                <div className="leading-relaxed text-gray-600 text-lg space-y-2">
                                    <div className="leading-relaxed text-gray-600 text-lg">
                                        <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                                    </div>

                                    <ul className="list-disc pl-5 space-y-1 mt-2">
                                        <li className="leading-relaxed">
                                            <strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong>
                                            <p>We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>
                                        </li>
                                        <li className="leading-relaxed">
                                            <strong>To deliver and facilitate delivery of services to the user.</strong>
                                            <p>We may process your information to provide you with the requested service.</p>
                                        </li>
                                        <li className="leading-relaxed">
                                            <strong>To respond to user inquiries/offer support to users.</strong>
                                            <p>We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</p>
                                        </li>
                                        <li className="leading-relaxed">
                                            <strong>To send administrative information to you.</strong>
                                            <p>We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</p>
                                        </li>
                                        <li className="leading-relaxed">
                                            <strong>To fulfill and manage your orders.</strong>
                                            <p>We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</p>
                                        </li>
                                        <li className="leading-relaxed">
                                            <strong>To enable user-to-user communications.</strong>
                                            <p>We may process your information if you choose to use any of our offerings that allow for communication with another user.</p>
                                        </li>
                                        <li>
                                            <strong>
                                                To save or protect an individual's vital interest.
                                            </strong>
                                            <br />
                                            We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="leading-relaxed">
                            <div className="py-2">
                                <br />
                            </div>
                            <div id="legalbases" className="leading-relaxed py-2 text-gray-900">
                                <strong className="text-lg">
                                    <span>
                                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                                    </span>
                                </strong>
                            </div>
                            {/* <div className="py-2">
                                                <br />
                                            </div> */}
                            <div className="leading-relaxed">
                                <em className="text-lg text-gray-700">
                                    <strong>In Short: </strong>
                                    We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
                                </em>
                            </div>
                            <div className="leading-relaxed">
                                <div className="py-2 text-gray-900">
                                    <em className="text-lg font-bold underline">
                                        If you are located in the EU or UK, this section applies to you.
                                    </em>
                                </div>

                                <div className="py-2">
                                    <span className="text-lg text-gray-700">
                                        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                                    </span>
                                </div>
                                <ul className="list-disc pl-5 mt-2">
                                    <li className="text-lg leading-relaxed text-gray-700">
                                        <strong>Consent. </strong>
                                        <br />
                                        We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click{' '}
                                        {/* <Link to="./" fragment="withdrawconsent" className="text-blue-500 hover:underline">
                                                            here
                                                        </Link>{' '} */}
                                        to learn more.
                                    </li>
                                </ul>
                                <div className="leading-relaxed">
                                    <span className="block">
                                    </span>
                                    <ul className="list-disc pl-5">
                                        <li className="leading-relaxed text-gray-700">
                                            <span className="text-lg">
                                                <strong>Performance of a Contract.</strong><br />
                                                We may process your personal information when we believe it is necessary to
                                                <span className="block">
                                                    fulfil
                                                    our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="leading-relaxed">
                                    <ul className="list-disc pl-5">
                                        <li className="leading-relaxed text-lg text-gray-700">
                                            <strong>Legal Obligations.</strong><br />
                                            We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                                            <br />
                                        </li>
                                    </ul>
                                </div>
                                <div className="leading-relaxed">
                                    <ul className="list-disc pl-5">
                                        <li className="leading-relaxed text-lg text-gray-700">
                                            <strong>Vital Interests.</strong><br />
                                            We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                                        </li>
                                    </ul>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="leading-relaxed">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-900">
                                            <strong><u><em>If you are located in Canada, this section applies to you.</em></u></strong>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        We may process your information if you have given us specific permission (i.e.
                                        express consent
                                        ) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e.
                                        implied consent
                                        ). You can withdraw your consent at any time. Click
                                        {/* <Link to="./#withdrawconsent" className="text-blue-500 hover:underline">
                                                            here
                                                        </Link> */}
                                        to learn more.
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                                    </span>
                                </div>
                                <ul className="list-disc pl-5 mt-2 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        For investigations and fraud detection and prevention
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        For business transactions provided certain conditions are met
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        For identifying injured, ill, or deceased persons and communicating with next of kin
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If the collection is solely for journalistic, artistic, or literary purposes
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mb-0">
                                    <li className="leading-relaxed text-lg text-gray-700">
                                        If the information is publicly available and is specified by the regulations
                                    </li>
                                </ul>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div id="whoshare" className="leading-relaxed text-gray-700">
                            <span className="text-gray-600 text-lg">
                                <span className="text-gray-600 text-lg">
                                    <span className="text-gray-600 text-lg">
                                        <span className="text-gray-600 text-lg">
                                            <span id="control" className="text-black font-bold">
                                                <span className="text-lg">
                                                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <div className="leading-relaxed">
                                <br />
                                <div className="leading-relaxed">
                                    <span className="text-gray-600 text-lg">
                                        <strong><em>In Short:</em></strong> <em>We may share information in specific situations described in this section and/or with the following third parties.</em>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-gray-600 text-lg"></span>
                                </div>
                                <br />
                                <div className="leading-relaxed">
                                    <span className="text-lg">
                                        We may need to share your personal information in the following situations:
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="leading-relaxed">
                                        <span className="text-lg">
                                            <span className="block"></span>
                                        </span>
                                    </div>
                                    <ul className="list-disc pl-5">
                                        <li className="leading-relaxed">
                                            <span className="text-lg">
                                                <strong>Business Transfers.</strong><br />
                                                We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                                            </span>
                                        </li>
                                    </ul>
                                    <ul className="list-disc pl-5">
                                        <li className="leading-relaxed text-lg">
                                            <strong>Affiliates.</strong><br />
                                            We may share your information with our affiliates, in which case we will require those affiliates to
                                            <span className="block"></span>
                                            honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
                                        </li>
                                    </ul>
                                    <div className="leading-relaxed">
                                        <ul className="list-disc pl-5">
                                            <li className="leading-relaxed text-lg">
                                                <strong>Other Users.</strong><br />
                                                When you share personal information
                                                {/* <span className="block"></span> */}
                                                (for example, by posting comments, contributions, or other content to the Services)
                                                {/* <span className="block"></span> */}
                                                or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity.
                                                {/* <span className="block"></span> */}
                                                Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="leading-relaxed">
                                <div className="leading-relaxed">
                                    <span className="block text-lg">
                                        <span className="block"></span>
                                    </span>
                                </div>
                                <div className="leading-relaxed my-6">
                                    <strong>
                                        <span className="text-lg text-gray-900 pt-2" id="3pwebsites">
                                            <span className="font-bold">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
                                        </span>
                                    </strong>
                                </div>
                                <div className="leading-relaxed my-2">
                                    <span className="text-lg">
                                        <strong><em>In Short:</em></strong>
                                        <em> We may use cookies and other tracking technologies to collect and store your information.</em>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="leading-relaxed my-1">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-600">
                                            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="leading-relaxed">
                                <div className="leading-relaxed my-2">
                                    <br />
                                </div>
                                <div id="sociallogins" className="leading-relaxed">
                                    <span className="text-gray-500">
                                        <span className="text-gray-700 text-lg">
                                            <span className="text-gray-700 text-lg">
                                                <span className="text-gray-700 text-lg">
                                                    <span id="control" className="text-black">
                                                        <strong className="text-lg">
                                                            <span>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed my-1">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg text-gray-700">
                                        <strong>
                                            <em>In Short: </em>
                                        </strong>
                                        <em>
                                            If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
                                        </em>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="leading-relaxed">
                                <div className="my-2">
                                    <br />
                                </div>
                                <div id="inforetain" className="leading-relaxed">
                                    <span className="text-gray-600">
                                        <span className="text-gray-700 text-lg">
                                            <span className="text-gray-700 text-lg">
                                                <span className="text-gray-700 text-lg">
                                                    <span className="text-black font-bold text-lg">
                                                        <span className="text-lg font-bold">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="my-2">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-gray-700 text-lg">
                                        <strong><em>In Short: </em></strong>
                                        <em>
                                            We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
                                        </em>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than
                                            <span className="text-gray-700 text-lg">

                                                __________
                                                <span className="text-gray-700 text-lg"> months past the termination of the user's account.
                                                </span>
                                            </span>

                                            <span className="text-gray-700 text-lg">
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize
                                            such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                                            <span className="text-gray-700 text-lg">
                                                <span className="block"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-900 text-lg">
                                            <span className="text-black">
                                                <strong>
                                                    <span className="font-bold">
                                                        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                                    </span>
                                                </strong>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            <strong>
                                                <em>In Short: </em>
                                            </strong>
                                            <em>
                                                We aim to protect your personal information through a system of
                                                {/* <span className="block"></span> */}
                                                organisational
                                                {/* <span className="block"></span> */}
                                                and technical security measures.
                                            </em>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            <span className="text-gray-700 text-lg">
                                                <span className="text-gray-700 text-lg">
                                                    We have implemented appropriate and reasonable technical and
                                                    {/* <span className="block"></span> */}
                                                    organisational
                                                    {/* <span className="block"></span> */}
                                                    security measures designed to protect the security of any personal
                                                    information we process. However, despite our safeguards and efforts
                                                    to secure your information, no electronic transmission over the
                                                    Internet or information storage technology can be guaranteed to be
                                                    100% secure, so we cannot promise or guarantee that hackers,
                                                    cybercriminals, or other
                                                    {/* <span className="block"></span> */}
                                                    unauthorised
                                                    {/* <span className="block"></span> */}
                                                    third parties will not be able to defeat our security and improperly
                                                    collect, access, steal, or modify your information. Although we
                                                    will do our best to protect your personal information, transmission
                                                    of personal information to and from our Services is at your own
                                                    risk. You should only access the Services within a secure environment.
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-500">
                                            <span className="text-gray-700 text-lg">
                                                <span className="text-black font-bold">
                                                    <strong>
                                                        <span className="text-lg font-bold">
                                                            9. DO WE COLLECT INFORMATION FROM MINORS?
                                                        </span>
                                                    </strong>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-gray-700 text-lg">
                                            <strong>
                                                <em>In Short:</em>
                                            </strong>
                                            <em> We do not knowingly collect data from or market to children under 18 years of age.</em>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
                                            represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
                                            minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of
                                            age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
                                            data from our records. If you become aware of any data we may have collected from children under age 18, please
                                            contact us at <span className="text-blue-600">info@Stashify.com</span>.
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div id="privacyrights" className="leading-relaxed">
                                        <span className="text-lg text-gray-500">
                                            <span className="text-gray-700 text-lg">
                                                <span className="text-black">
                                                    <strong>
                                                        <span data-custom-class="heading_1">10. WHAT ARE YOUR PRIVACY RIGHTS?</span>
                                                    </strong>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span data-custom-class="body_text">
                                                <strong>
                                                    <em>In Short:</em>
                                                </strong>
                                                <em>
                                                    &nbsp;<span className="text-gray-700">
                                                        {/* <span className="text-lg">
                                                                            <span data-custom-class="body_text">
                                                                                <em>
                                                                                    <span className="block-component"></span>
                                                                                </em>
                                                                            </span>
                                                                        </span> */}
                                                    </span>In some regions, such as
                                                    <span className="block-component"></span> the European Economic Area (EEA),
                                                    United Kingdom (UK), and Canada
                                                    <span className="block-component"></span>, you have rights that allow you
                                                    greater access to and control over your personal information.
                                                    <span className="text-lg text-gray-700">
                                                        <span data-custom-class="body_text">
                                                            <em>
                                                                <span className="statement-end-if-in-editor"></span>
                                                            </em>
                                                        </span>
                                                    </span>
                                                    &nbsp;You may review, change, or terminate your account at any time.
                                                </em>
                                                <span className="text-gray-700">
                                                    <span className="text-lg">
                                                        <span className="block-component"></span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    In some regions (like
                                                    {/* <span className="block-component"></span>  */}
                                                    the EEA, UK, and Canada
                                                    {/* <span className="block-component"></span> */}
                                                    ), you have certain rights under applicable data protection laws. These may include the right:
                                                    <ul className="list-disc ml-4">
                                                        <li>To request access and obtain a copy of your personal information</li>
                                                        <li>To request rectification or erasure</li>
                                                        <li>To restrict the processing of your personal information</li>
                                                        <li>If applicable, to data portability</li>
                                                    </ul>
                                                    In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the contact details provided in the section
                                                    {/* <span className="block-component"></span>' */}
                                                    <a data-custom-class="link" href="#contact" className="text-blue-600 underline">
                                                        HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                    </a>
                                                    {/* <span className="block-component"></span> */}
                                                    below.
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    We will consider and act upon any request in accordance with applicable data protection laws.
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">&nbsp;</span>
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here:
                                                    <a
                                                        className="text-blue-600 underline"
                                                        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                    </a>
                                                    .
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <div className="my-2">
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    If you are located in Switzerland, the contact details for the data protection authorities are available here:
                                                    <a
                                                        className="text-blue-600 underline"
                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        https://www.edoeb.admin.ch/edoeb/en/home.html
                                                    </a>
                                                    .
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="leading-relaxed my-2">
                                        <br />
                                    </div>
                                    <div id="withdrawconsent" className="leading-relaxed">
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        <u>Withdrawing your consent:</u>
                                                    </strong>
                                                    <br />
                                                    If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section
                                                </span>
                                            </span>
                                        </span>
                                        <a
                                            className="text-lg text-gray-700 underline"
                                            href="#contact"
                                        >
                                            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                        </a>
                                        <span className="text-lg text-gray-700">
                                            <span className="text-lg text-gray-700">
                                                <span data-custom-class="body_text">
                                                    below or updating your preferences.
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg">
                                        <span className="body_text">
                                            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,{' '}
                                            <span className="block-component" />
                                            when applicable law allows,{' '}
                                            <span className="statement-end-if-in-editor" />
                                            will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                                            <span className="block-component" />
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text">
                                            <strong>
                                                <u>Opting out of marketing and promotional communications:</u>
                                            </strong>
                                            <strong>
                                                <u>&nbsp;</u>
                                            </strong>
                                            <br />
                                            You can unsubscribe from our marketing and promotional communications at any time by{' '}
                                            <span className="block-component"></span> clicking on the unsubscribe link in the emails that we send,{' '}
                                            <span className="statement-end-if-in-editor"></span> <span className="block-component"></span>{' '}
                                            <span className="block-component"></span> or by contacting us using the details provided in the section{' '}
                                            <span className="block-component"></span> '<span className="else-block"></span>{' '}
                                        </span>
                                    </span>
                                    <a className="text-blue-500" href="/#contact">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
                                        </span>
                                    </a>
                                    <span className="text-lg">
                                        <span data-custom-class="body_text">
                                            <span className="block-component"></span>'<span className="else-block"></span> below. You will then be removed from
                                            the marketing lists. However, we may still communicate with you — for example, to send you service-related messages
                                            that are necessary for the administration and use of your account, to respond to service requests, or for other
                                            non-marketing purposes.
                                        </span>
                                        <span data-custom-class="body_text">
                                            <span className="statement-end-if-in-editor"></span>
                                        </span>
                                    </span>
                                    <span className="block-component">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text"></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg">
                                        <span data-custom-class="heading_2">
                                            <strong>Account Information</strong>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span data-custom-class="body_text">
                                        <span className="text-lg">
                                            If you would at any time like to review or change the information in your account or terminate your account, you can:
                                            <span className="forloop-component" />
                                        </span>
                                    </span>
                                </div>
                                <ul className="mt-[0.7rem]">
                                    <li className="leading-relaxed">
                                        <span data-custom-class="body_text">
                                            <span className="text-lg">
                                                <span className="question">
                                                    Log in to your account settings and update your user account.
                                                </span>
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                                <div className="leading-relaxed">
                                    <span data-custom-class="body_text">
                                        <span className="text-lg">
                                            <span className="forloop-component" />
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <span className="text-lg">
                                        <span data-custom-class="body_text">
                                            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                                        </span>
                                    </span>
                                    <span className="statement-end-if-in-editor">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text"></span>
                                        </span>
                                    </span>
                                    <span className="text-[#595959] text-lg">
                                        <span data-custom-class="body_text">
                                            <span className="block-component"></span>
                                        </span>
                                    </span>
                                    <span className="block-component">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text"></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed">
                                    <span data-custom-class="body_text" className="text-lg">
                                        If you have questions or comments about your privacy rights, you may email us at{' '}
                                        <span className="question">info@genieaz.com</span>.
                                    </span>
                                    <span className="statement-end-if-in-editor">
                                        <span className="text-lg">
                                            <span data-custom-class="body_text"></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div id="DNT" className="leading-relaxed">
                                    <span className="text-[#7f7f7f]">
                                        <span className="text-[#595959] text-lg">
                                            <span id="control" className="text-[#000000]">
                                                <strong>
                                                    <span data-custom-class="heading_1">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed text-lg text-[#595959]">
                                    <span>
                                        Most web browsers and some mobile operating systems and mobile applications include a
                                        Do-Not-Track (
                                        <span className="text-[#595959]">'DNT'</span>
                                        ) feature or setting you can activate to signal your privacy preference not to have data
                                        about your online browsing activities monitored and collected. At this stage no uniform
                                        technology standard for
                                        <span className="text-[#595959]">
                                            recognising and implementing DNT signals has been finalised.
                                        </span>
                                        As such, we do not currently respond to DNT browser signals or any other mechanism that
                                        automatically communicates your choice not to be tracked online. If a standard for online
                                        tracking is adopted that we must follow in the future, we will inform you about that
                                        practice in a revised version of this privacy notice.
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div id="caresidents" className="leading-relaxed">
                                    <span className="text-[#7f7f7f]">
                                        <span className="text-[#595959] text-lg">
                                            <span id="control" className="text-[#000]">
                                                <strong className="text-xl">
                                                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                                                </strong>
                                            </span>
                                        </span>
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        <strong className="text-lg">
                                            <em>In Short: </em>
                                        </strong>
                                        <em>
                                            Yes, if you are a resident of California, you are granted specific rights regarding access
                                            to your personal information.
                                        </em>
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        California Civil Code Section 1798.83, also known as the
                                        <span className="font-semibold">'Shine The Light'</span> law,
                                        permits our users who are California residents to request and obtain from us,
                                        once a year and free of charge, information about categories of personal information
                                        (if any) we disclosed to third parties for direct marketing purposes and the names
                                        and addresses of all third parties with which we shared personal information in
                                        the immediately preceding calendar year. If you are a California resident and
                                        would like to make such a request, please submit your request in writing to us
                                        using the contact information provided below.
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        If you are under 18 years of age, reside in California, and have a registered account
                                        with Services, you have the right to request removal of unwanted data that you publicly
                                        post on the Services. To request removal of such data, please contact us using the
                                        contact information provided below and include the email address associated with
                                        your account and a statement that you reside in California. We will make sure the
                                        data is not publicly displayed on the Services, but please be aware that the data
                                        may not be completely or comprehensively removed from all our systems (e.g.
                                        <span className="block-component"></span> backups, etc.).
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-black text-lg font-bold">
                                        CCPA Privacy Notice
                                    </span>
                                </div>

                                <div>
                                    <div>
                                        <br />
                                    </div>
                                    <div className="leading-relaxed">
                                        <span className="text-[#7f7f7f] text-lg">
                                            <span className="text-[#595959] text-lg">
                                                The California Code of Regulations defines a
                                                <span className="block-component"></span>
                                                'resident'
                                                <span className="else-block"></span>
                                                as:
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>
                                <div className="leading-relaxed ml-5">
                                    <span className="text-[#595959] text-lg">
                                        (1) every individual who is in the State of California for other than a temporary or transitory purpose and
                                    </span>
                                </div>

                                <div className="leading-relaxed ml-5">
                                    <span className="text-[#595959] text-lg">
                                        (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        All other individuals are defined as
                                        <span className="block-component"></span>
                                        'non-residents'.
                                        <span className="else-block"></span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        If this definition of
                                        <span className="block-component"></span>
                                        'resident'
                                        <span className="else-block"></span>
                                        applies to you, we must adhere to certain rights and obligations regarding your personal information.
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        <strong>What categories of personal information do we collect?</strong>
                                    </span>
                                </div>

                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <div className="leading-relaxed">
                                    <span className="text-[#595959] text-lg">
                                        We have collected the following categories of personal information in the past twelve (12) months:
                                        <span className="text-[#595959] text-lg">
                                            <span className="block-component"></span>
                                        </span>
                                    </span>
                                </div>
                                <div className="leading-relaxed">
                                    <br />
                                </div>

                                <table className="w-full border-collapse border border-black">
                                    <tbody>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <span className="text-[#595959] text-lg font-bold">Category</span>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <span className="text-[#595959] text-lg font-bold">Examples</span>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <span className="text-[#595959] text-lg font-bold">Collected</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">A. Identifiers</span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center vertical-align-middle px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        B. Personal information categories listed in the California Customer Records statute
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Name, contact information, education, employment, employment history, and financial information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        C. Protected classification characteristics under California or federal law
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Gender and date of birth
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        NO
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        D. Commercial information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Transaction information, purchase history, financial details, and payment information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        E. Biometric information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Fingerprints and voiceprints
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        NO
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        F. Internet or other similar network activity
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] border border-black text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        NO
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black border-l-0 border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        G. Geolocation data
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Device location
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] text-center border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black border-l-0 border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        H. Audio, electronic, visual, thermal, olfactory, or similar information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Images and audio, video or call recordings created in connection with our business activities
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] text-center border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="w-[33.8274%] border border-black border-l-0 border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        I. Professional or employment-related information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] text-center border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black border-l-0 border-r-0 border-t-0 w-[33.8274%] px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        J. Education Information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="border border-black border-r-0 border-t-0 w-[51.4385%] px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Student records and directory information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="border border-black border-r-0 border-t-0 w-[14.9084%] text-center px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        NO
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black border-l-0 border-r-0 border-t-0 w-[33.8274%] px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        K. Inferences drawn from other personal information
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[51.4385%] border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="w-[14.9084%] text-center border border-black border-r-0 border-t-0 px-4 py-2">
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                                <div className="leading-relaxed">
                                                    <span className="text-[#595959] text-lg">
                                                        YES
                                                    </span>
                                                </div>
                                                <div className="leading-relaxed">
                                                    <br />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border border-black border-l-0 border-r-0 border-t-0 w-[33.8274%] px-4 py-2">
                                                <span className="text-lg">
                                                    L. Sensitive Personal Information
                                                </span>
                                            </td>
                                            <td className="border border-black border-r-0 border-t-0 border-b-0 w-[51.4385%] px-4 py-2">
                                                <span className="block">
                                                    <span className="text-lg"></span>
                                                </span>
                                                <span className="text-lg">
                                                    <span className="text-lg"></span>
                                                    <span className="block">
                                                        <span className="text-lg">
                                                            <span className="text-lg">Account login information</span>
                                                            <span className="text-lg">
                                                                , biometric data, debit or credit card numbers, drivers' licenses, passport numbers
                                                                ,
                                                                precise geolocation
                                                                ,
                                                                state id card numbers
                                                                <span className="inline-block"></span>
                                                                and
                                                                union membership
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </td>
                                            <td className="border border-black border-r-0 border-b-0 border-t-0 w-[14.9084%] text-center px-4 py-2">
                                                <br />
                                                <span className="block">
                                                    <span className="text-lg"></span>
                                                </span>
                                                <span className="text-lg">
                                                    YES
                                                    <span className="inline-block"></span>
                                                </span>
                                                <br /><br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="leading-6">
                                    <span className="block"></span>
                                    <div className="leading-6">
                                        <br />
                                    </div>
                                    <div className="leading-6">
                                        <span className="block">
                                            <span className="text-lg"></span>
                                        </span>
                                        <span className="text-lg">
                                            We will use and retain the collected personal information as needed to provide the Services or for:
                                            <span className="block"></span>
                                        </span>
                                    </div>
                                    <ul className="list-disc pl-5 leading-6 mt-2">
                                        <li className="leading-6">
                                            <span className="text-lg">
                                                Category A -
                                                <span className="font-bold">As long as the user has an account with us</span>
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="leading-6 mt-2">
                                        <span className="text-lg">
                                            <span className="block"></span>
                                        </span>
                                        <ul className="list-disc pl-5 leading-6">
                                            <li className="leading-6">
                                                <span className="text-lg">
                                                    Category B -
                                                    <span className="font-bold">
                                                        As long as the user has an account with us
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div className="leading-6">
                                            <ul className="list-disc pl-5 leading-6  mt-2">
                                                <li className="leading-6">
                                                    <span className="text-lg">
                                                        Category D -
                                                        <span className="font-bold">
                                                            As long as the user has an account with us
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="leading-6">
                                            <ul className="list-disc pl-5 leading-6  mt-2">
                                                <li className="leading-6">
                                                    <span className="text-lg">
                                                        Category G -
                                                        <span className="font-bold">
                                                            As long as the user has an account with us
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>

                                            <ul className="list-disc pl-5 leading-6  mt-2">
                                                <li className="leading-6">
                                                    <span className="text-lg">
                                                        Category H -
                                                        <span className="font-bold">
                                                            As long as the user has an account with us
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="leading-6">

                                            <ul className="list-disc pl-5 leading-6  mt-2">
                                                <li className="leading-6">
                                                    <span className="text-lg">
                                                        Category I -
                                                        <span className="font-bold">
                                                            As long as the user has an account with us
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                            <div className="leading-6">
                                                <ul className="list-disc pl-5 leading-6 mt-2">
                                                    <li className="leading-6">
                                                        <span className="text-lg">
                                                            Category K -
                                                            <span className="font-bold">
                                                                As long as the user has an account with us
                                                            </span>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="leading-6">
                                            <ul className="list-disc pl-5 leading-6 mt-2">
                                                <li className="leading-6">
                                                    <span className="text-lg">
                                                        Category L -
                                                        <span className="font-bold">
                                                            As long as the user has an account with us
                                                        </span>
                                                    </span>
                                                    <span className="hidden"></span>
                                                </li>
                                            </ul>

                                            <div className="leading-6 text-lg text-gray-600 mt-2">
                                                <span className="block"></span>
                                                Category L information may be used, or disclosed to a service provider or contractor, for additional, specified purposes. You have the right to limit the use or disclosure of your sensitive personal information.
                                            </div>
                                        </div>
                                        <div className="leading-6">
                                            <div className="leading-6">
                                                <br />
                                            </div>

                                            <div className="leading-6 text-lg text-gray-600">
                                                <span className="text-lg text-gray-600">
                                                </span>
                                            </div>

                                            <div className="leading-6 text-lg text-gray-600">
                                                We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:

                                            </div>

                                            <ul className="list-disc pl-5 leading-6 text-lg text-gray-600">
                                                <li className="leading-6 text-lg text-gray-600">
                                                    Receiving help through our customer support channels;
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-lg text-gray-600">

                                            <ul className="list-disc pl-5 leading-6">
                                                <li className="leading-6">
                                                    <span className="text-lg text-gray-600">
                                                        Participation in customer surveys or contests; and
                                                    </span>
                                                </li>
                                            </ul>

                                            <ul className="list-disc pl-5 leading-6">
                                                <li className="leading-6">
                                                    <span className="text-lg text-gray-600">
                                                        Facilitation in the delivery of our Services and to respond to your inquiries.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="text-lg text-gray-600 leading-6 mt-4">
                                            <div>
                                                <strong className="text-lg text-gray-600">
                                                    How do we use and share your personal information?
                                                </strong>
                                            </div>

                                            <div>
                                                <span className="text-lg text-gray-600 mt-2">
                                                    More information about our data collection and sharing practices can be
                                                    found in this privacy notice.
                                                </span>
                                            </div>

                                            <div>
                                                <span className="text-lg text-gray-600 mt-4">
                                                    You may contact us by email at{' '}
                                                    <a href="mailto:dpo@Stashify.com" className="text-blue-600">
                                                        dpo@Stashify.com
                                                    </a>
                                                    , or by referring to the contact details at the bottom of this document.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="text-lg text-gray-600 leading-6 mt-4">

                                            <div>
                                                <span className="text-lg text-gray-600">
                                                    If you are using an authorised agent to exercise your right to opt out, we
                                                    may deny a request if the authorised agent does not submit proof that
                                                    they have been validly authorised to act on your behalf.
                                                </span>
                                            </div>

                                            <div>
                                                <br />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <span className="text-lg text-gray-600">
                                <strong>Will your information be shared with anyone else?</strong>
                            </span>
                        </div>
                        <div className="text-lg text-gray-600 leading-6">

                            <div>
                                <span className="text-lg text-gray-600">
                                    We may disclose your personal information with our service providers pursuant
                                    to a written contract between us and each service provider. Each service
                                    provider is a for-profit entity that processes the information on our behalf,
                                    following the same strict privacy protection obligations mandated by the CCPA.
                                </span>
                            </div>

                            <div>
                                <br />
                            </div>

                            <div>
                                <span className="text-lg text-gray-600">
                                    We may use your personal information for our own business purposes, such as for
                                    undertaking internal research for technological development and demonstration.
                                    This is not considered to be 'selling' of your personal information.
                                </span>
                            </div>
                        </div>
                        <div className="text-lg text-gray-600 leading-6">
                            <div>
                                <br />
                            </div>

                            <div>
                                <span className="text-lg text-gray-600">
                                    <span className="font-semibold">STASHIFY TECHNOLOGIES LIMITED</span>
                                    {' '}
                                    has not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.
                                    {' '}
                                    <span className="font-semibold">STASHIFY TECHNOLOGIES LIMITED</span>
                                    {' '}
                                    will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                                </span>
                            </div>
                        </div>
                        <div className="text-lg text-gray-600 leading-6">
                            <div>
                                <br />
                            </div>

                            <div>
                                <strong>Your rights with respect to your personal data</strong>
                            </div>

                            <div>
                                <br />
                            </div>

                            <div>
                                <u>Right to request deletion of the data — Request to delete</u>
                            </div>

                            <div>
                                <br />
                            </div>

                            <div>
                                You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
                            </div>
                        </div>
                        <div className="text-lg text-gray-600 leading-6">
                            <div>
                                <br />
                            </div>

                            <div>
                                <u>Right to be informed — Request to know</u>
                            </div>

                            <div>
                                <br />
                            </div>

                            <div>
                                Depending on the circumstances, you have a right to know:
                            </div>

                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>whether we collect and use your personal information;</li>
                            </ul>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>
                                    <span>The categories of personal information that we collect;</span>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>
                                    <span>
                                        The purposes for which the collected personal information is used;
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>
                                    <span>
                                        Whether we sell or share personal information to third parties;
                                    </span>
                                </li>
                            </ul>
                            <ul className="list-disc list-inside ml-4 mt-2">
                                <li>
                                    <span>
                                        The categories of personal information that we sold, shared, or disclosed for a business purpose;
                                    </span>
                                </li>
                            </ul>
                            <div className="text-lg text-gray-600 leading-6">
                                <ul className="list-disc list-inside ml-4 mt-2">
                                    <li>
                                        <span>
                                            The categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-disc list-inside ml-4 mt-2">
                                    <li>
                                        <span>
                                            The business or commercial purpose for collecting, selling, or sharing personal information; and
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-disc list-inside ml-4 mt-2">
                                    <li>
                                        <span>
                                            The specific pieces of personal information we collected about you.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6 mt-2">
                                <div>
                                    <span>
                                        In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>

                                <div>
                                    <span className="underline pt-4">
                                        Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>
                                <div>
                                    <span>
                                        We will not discriminate against you if you exercise your privacy rights.
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>
                                <div>
                                    <span className="underline">
                                        Right to Limit Use and Disclosure of Sensitive Personal Information
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">

                                <div>
                                    <span>
                                        If the business collects any of the following:
                                    </span>
                                </div>

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            social security information, drivers' licenses, state ID cards, passport numbers
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            account login information
                                        </span>
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            credit card numbers, financial account information, or credentials allowing access to such accounts
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            precise geolocation
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            racial or ethnic origin, religious or philosophical beliefs, union membership
                                        </span>
                                    </li>
                                </ul>
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            the contents of email and text, unless the business is the intended recipient of the communication
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            genetic data, biometric data, and health data
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            data concerning sexual orientation and sex life
                                        </span>
                                    </li>
                                </ul>

                                <div className="mt-2">
                                    <span>
                                        you have the right to direct that business to limit its use of your sensitive personal information to that use which is necessary to perform the Services.
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        Once a business receives your request, they are no longer allowed to use or disclose your sensitive personal information for any other purpose unless you provide consent for the use or disclosure of sensitive personal information for additional purposes.
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                <div>
                                    <br />
                                </div>
                                <div>
                                    <span>
                                        Please note that sensitive personal information that is collected or processed without the purpose of inferring characteristics about a consumer is not covered by this right, as well as the publicly available information.
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>

                                <div>
                                    To exercise your right to limit use and disclosure of sensitive personal information, please{' '}
                                    <a
                                        href="mailto:dpo@Stashify.com"
                                        className="text-blue-600 hover:underline"
                                    >
                                        email
                                    </a>{' '}
                                    or{' '}
                                    <a
                                        href="https://app.termly.io/notify/7ba50094-def9-499a-aa2f-4c3901255b3c"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:underline"
                                    >
                                        submit a request form here
                                    </a>
                                    .
                                </div>
                                <div>
                                    <br />
                                </div>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                <div>
                                    <br />
                                </div>
                                <div>
                                    <span className="text-gray-600 text-lg underline">
                                        Verification process
                                    </span>
                                </div>
                                <div>
                                    <br />
                                </div>

                                <div>
                                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                                </div>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                <div>
                                    <span className="block h-4"></span>
                                </div>
                                <div>
                                    <br />
                                </div>

                                <div>
                                    <p>
                                        We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                                    </p>
                                </div>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                <div className="h-4"></div>
                                <div>
                                    <br />
                                </div>
                                <div>
                                    <span className="font-semibold underline">Other privacy rights</span>
                                </div>
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            You may object to the processing of your personal information.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            You may request correction of your personal data if it is incorrect
                                            or no longer relevant, or ask to restrict the processing of the
                                            information.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                {/* List of rights */}
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            You can designate an authorized agent to make a request under the
                                            CCPA on your behalf. We may deny a request from an authorized agent
                                            that does not submit proof that they have been validly authorized to
                                            act on your behalf in accordance with the CCPA.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6">
                                {/* Opt-out information */}
                                <ul className="list-disc pl-5 mt-2">
                                    <li>
                                        <span>
                                            You may request to opt out from future selling or sharing of your
                                            personal information to third parties. Upon receiving an opt-out
                                            request, we will act upon the request as soon as feasibly possible,
                                            but no later than fifteen (15) days from the date of the request
                                            submission.
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="text-lg text-gray-600 leading-6 mt-2">
                                <span>
                                    To exercise these rights, you can contact us by email at{' '}
                                    <span className="text-blue-600 underline">dpo@Stashify.com</span>, or
                                    by referring to the contact details at the bottom of this document. If
                                    you have a complaint about how we handle your data, we would like to
                                    hear from you.
                                </span>
                            </div>
                            <div className="leading-6 text-lg">
                                <div className="mb-4">
                                    {/* Empty space */}
                                </div>

                                <div className="mb-4">
                                    {/* Empty space */}
                                </div>

                                <div className="mb-4">
                                    <h2 className="text-lg font-bold" id="virginia">
                                        13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                                    </h2>
                                </div>

                                <div className="mb-4">
                                    {/* Empty space */}
                                </div>

                                <div className="mb-4">
                                    <p className="italic">
                                        <span className="text-gray-600 font-bold">In Short:</span> Yes, if you are a
                                        resident of Virginia, you may be granted specific rights regarding
                                        access to and use of your personal information.
                                    </p>
                                </div>

                                <div className="mb-4">
                                    <h3 className="text-lg font-bold">
                                        Virginia CDPA Privacy Notice
                                    </h3>
                                </div>

                            </div>
                            <div className="leading-6 text-lg">
                                <div className="mb-4">
                                    <p>
                                        Under the Virginia Consumer Data Protection Act (CDPA):
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        <span className="font-normal">
                                            'Consumer' means a natural person who is a resident of the
                                            Commonwealth acting only in an individual or household context. It
                                            does not include a natural person acting in a commercial or
                                            employment context.
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="leading-6 text-lg">
                                <div className="mb-4">
                                    {/* Empty space */}
                                </div>

                                <div className="mb-4">
                                    <p>
                                        <span className="font-normal">
                                            'Personal data' means any information that is linked or reasonably
                                            linkable to an identified or identifiable natural person.
                                            'Personal data' does not include de-identified data or publicly
                                            available information.
                                        </span>
                                    </p>
                                </div>

                                <div className="mb-4">
                                    <p>
                                        <span className="font-normal">
                                            'Sale of personal data' means the exchange of personal data for
                                            monetary consideration.
                                        </span>
                                    </p>
                                </div>

                                <div>
                                    <p>
                                        <span className="font-normal">
                                            If this definition 'consumer' applies to you, we must adhere to
                                            certain rights and obligations regarding your personal data.
                                        </span>
                                    </p>
                                </div>
                                <div className="leading-6 text-lg">
                                    {/* Empty space */}
                                    <div className="mb-4"></div>

                                    <div className="mb-4">
                                        <p>
                                            The information we collect, use, and disclose about you will vary
                                            depending on how you interact with{' '}
                                            <span className="font-semibold">STASHIFY TECHNOLOGIES LIMITED</span>
                                            {' '}and our Services. To find out more, please visit the following
                                            links:
                                        </p>
                                    </div>

                                    <ul className="list-disc list-inside mb-4">
                                        <li>
                                            <a className="text-blue-600 underline hover:text-blue-800" href="#infocollect">
                                                Personal data we collect
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="mb-4"></div>

                                    <ul className="list-disc list-inside mb-4">
                                        <li>
                                            <a className="text-blue-600 underline hover:text-blue-800" href="#infouse">
                                                How we use your personal data
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="mb-4"></div>

                                    <ul className="list-disc list-inside mb-4">
                                        <li>
                                            <a className="text-blue-600 underline hover:text-blue-800" href="#whoshare">
                                                When and with whom we share your personal data
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="leading-6 text-lg">
                                    {/* <div className="mb-4"> */}
                                    <p className="font-semibold underline">
                                        Your rights with respect to your personal data
                                    </p>
                                    {/* </div> */}

                                    <div className="mb-4"></div>

                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to be informed whether or not we are processing your personal data
                                            </p>
                                        </li>
                                    </ul>

                                    <div className="mb-4"></div>

                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to access your personal data
                                            </p>
                                        </li>
                                    </ul>

                                    <div className="mb-4"></div>

                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to correct inaccuracies in your personal data
                                            </p>
                                        </li>
                                    </ul>

                                    <div className="mb-4"></div>

                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to request deletion of your personal data
                                            </p>
                                        </li>
                                    </ul>
                                    {/* Empty space div */}
                                    <div className="mb-4">
                                        <span className="block-component"></span>
                                    </div>

                                    {/* List of Rights */}
                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to obtain a copy of the personal data you previously shared with us
                                            </p>
                                        </li>
                                    </ul>

                                    {/* Empty space div */}
                                    <div className="mb-4">
                                        <span className="block-component"></span>
                                    </div>

                                    <ul className="list-disc mb-4 pl-5">
                                        <li>
                                            <p>
                                                Right to opt out of the processing of your personal data if it is used for targeted advertising,
                                                the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly
                                                significant effects ('profiling')
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="leading-6 text-lg">
                                    {/* Privacy Statement Text */}
                                    <div className="mb-4">
                                        <span className="block">
                                            STASHIFY TECHNOLOGIES LIMITED has not sold any personal data to third parties for business or commercial purposes.
                                            STASHIFY TECHNOLOGIES LIMITED will not sell personal data in the future belonging to website visitors, users, and other consumers.
                                        </span>
                                    </div>

                                    {/* Empty line */}
                                    <div className="mb-4">
                                        <br />
                                    </div>

                                    {/* Exercise Rights Section */}
                                    <div>
                                        <span className="underline font-semibold text-lg">
                                            Exercise your rights provided under the Virginia CDPA
                                        </span>
                                    </div>
                                    <div className="leading-6 text-lg">
                                        {/* Empty Line */}
                                        <div className="my-4">
                                            {/* Using empty div for spacing */}
                                        </div>

                                        {/* Privacy Notice Information */}
                                        <div className="mb-4">
                                            <span>
                                                More information about our data collection and sharing practices can be found in this privacy notice.
                                            </span>
                                        </div>

                                        {/* Empty Line */}
                                        <div className="my-4">
                                            {/* Using empty div for spacing */}
                                        </div>

                                        {/* Contact Information */}
                                        <div>
                                            <span>
                                                You may contact us by email at{' '}
                                                <a href="mailto:dpo@Stashify.com" >
                                                    dpo@Stashify.com
                                                </a>
                                                , by visiting our{' '}
                                                <a
                                                    href="https://app.termly.io/notify/7ba50094-def9-499a-aa2f-4c3901255b3c"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    className="text-blue-600 underline"
                                                >
                                                    data subject request form
                                                </a>
                                                , or by referring to the contact details at the bottom of this document.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="leading-6 text-lg">
                                        {/* Empty Line */}
                                        <div className="my-4">
                                            {/* Using empty div for spacing */}
                                        </div>

                                        {/* Information about authorized agents */}
                                        <div className="mb-4">
                                            <span>
                                                If you are using an{' '}
                                                <span className="font-normal">
                                                    authorised agent
                                                </span>{' '}
                                                to exercise your rights, we may deny a request if the{' '}
                                                <span className="font-normal">
                                                    authorised agent
                                                </span>{' '}
                                                does not submit proof that they have been validly{' '}
                                                <span className="font-normal">
                                                    authorised
                                                </span>{' '}
                                                to act on your behalf.
                                            </span>
                                        </div>

                                        {/* Section Title */}
                                        <div className="my-4">
                                            <span className="font-semibold underline">
                                                Verification process
                                            </span>
                                        </div>

                                        {/* Additional Verification Information */}
                                        <div className="mb-4">
                                            <span>
                                                We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an{' '}
                                                <span className="font-normal">
                                                    authorised agent
                                                </span>, we may need to collect additional information to verify your identity before processing your request.
                                            </span>
                                        </div>
                                        <div className="leading-6 text-lg">
                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Response Time Information */}
                                            <div className="mb-4">
                                                <span>
                                                    Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.
                                                </span>
                                            </div>

                                            {/* Section Title */}
                                            <div className="my-4">
                                                <span className="font-semibold underline">
                                                    Right to appeal
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg">
                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Decline Action and Appeal Information */}
                                            <div className="mb-4">
                                                <span>
                                                    If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at{' '}
                                                    <a href="mailto:dpo@Stashify.com">
                                                        dpo@Stashify.com
                                                    </a>
                                                    . Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to{' '}
                                                    <a
                                                        href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className="text-blue-600 underline"
                                                    >
                                                        submit a complaint
                                                    </a>
                                                    .
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg">
                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Heading */}
                                            <div id="policyupdates" className="my-4">
                                                <span className="text-gray-600">
                                                    <span className="text-gray-700 text-lg">
                                                        <strong className="text-black">
                                                            <span className="font-bold">
                                                                14. DO WE MAKE UPDATES TO THIS NOTICE?
                                                            </span>
                                                        </strong>
                                                    </span>
                                                </span>
                                            </div>

                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Body Text */}
                                            <div>
                                                <span className="text-gray-700 text-lg">
                                                    <em>
                                                        <strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
                                                    </em>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Privacy Update Notice */}
                                            <div>
                                                <span>
                                                    We may update this privacy notice from time to time. The updated version will be indicated by an updated
                                                    <span className="font-semibold"> 'Revised' date</span> and the updated version will be effective as soon as
                                                    it is accessible. If we make material changes to this privacy notice, we may notify you either by
                                                    prominently posting a notice of such changes or by directly sending you a notification. We encourage you
                                                    to review this privacy notice frequently to be informed of how we are protecting your information.
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Contact Notice Header */}
                                            <div id="contact">
                                                <span className="text-gray-900 font-bold">
                                                    15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                                                </span>
                                            </div>

                                            {/* Empty Line */}
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Contact Information */}
                                            <div>
                                                <span>
                                                    If you have questions or comments about this notice, you may{' '}
                                                    <a
                                                        href="mailto:info@Stashify.com"
                                                    >
                                                        email us at info@Stashify.com
                                                    </a>{' '}
                                                    or by post to:
                                                </span>
                                            </div>
                                            <div className="my-4">
                                                {/* Using empty div for spacing */}
                                            </div>

                                            {/* Contact Address */}
                                            <div>
                                                <span className="text-gray-700">Stashify Technologies</span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            {/* Address Line 1 */}
                                            <div>
                                                <span className="text-gray-700">
                                                    1-75 Shelton Street
                                                </span>
                                            </div>

                                            {/* Address Line 2 */}
                                            <div>
                                                <span className="text-gray-700">
                                                    London, Greater London
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            <span className="text-gray-700">
                                                England
                                            </span>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            <div>
                                                <span className="text-gray-700">
                                                    WC2H 9JQ
                                                </span>
                                            </div>
                                            <div className="my-6" /> {/* This is for the <br> */}
                                            <div>
                                                <span className="text-gray-600">
                                                    <strong className="text-gray-700">
                                                        16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                                                    </strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="leading-6 text-lg text-gray-700">
                                            <div className="my-2" /> {/* This is for the <br> */}
                                            <div>
                                                <span>
                                                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please
                                                    <a
                                                        href="https://app.genieaz.com"
                                                    >
                                                        visit: app.genieaz.com
                                                    </a>
                                                    .
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    )
}

export default Privacy;