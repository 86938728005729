import React, { useState } from 'react';

import logo from "../assets/images/Stashify-logo.png"

function Header() {
    // Define state to manage the visibility of the menu
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    // Function to toggle the menu visibility
    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };
    return <header className="flex flex-row w-full bg-white shadow-md items-center 
    justify-center
    fixed
    z-20 py-4">
        <div className="flex flex-col md:flex-row w-full justify-between
        px-6
        md:px-32
        items-center">

            <div className="flex justify-between w-full md:w-auto">
                <a href="/" className="text-muted-foreground flex
                        flex-row
                        items-center transition-colors rounded-3xl
                        py-1
                        hover:bg-accent hover:text-accent-foreground
                        active
                        hover:text-blue-600 no-underline">
                    <img src={logo} className="w-32
                md:w-48
                m-0" alt="logo" /></a>
                <button id="menu-toggle" className="md:hidden text-gray-800
                focus:outline-none" onClick={toggleMenu}>
                    <svg className="w-6 h-6" fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>
            <nav id="menu" className='hidden md:flex'>
                <ul className="flex flex-col md:flex-row space-y-4
                md:space-y-0 md:space-x-4 no-underline w-full md:w-auto">
                    <li><a href="/" className="text-muted-foreground flex
                        flex-row
                        items-center transition-colors rounded-3xl
                        px-6
                        py-1
                        hover:bg-accent hover:text-accent-foreground
                        active
                        hover:text-blue-600 no-underline">Home</a></li>
                    <li><a href="#pricing" className="text-muted-foreground
                        flex
                        flex-row items-center transition-colors
                        rounded-3xl
                        px-6 py-1   hover:bg-accent
                        hover:text-accent-foreground active
                        hover:text-blue-600
                        no-underline">Pricing</a></li>
                    <li><a href="#contact" className="hover:text-blue-600
                        text-muted-foreground flex flex-row
                        items-center
                        transition-colors rounded-3xl px-6 py-1
                         
                        hover:bg-accent hover:text-accent-foreground
                        active
                        no-underline">Contact Us</a></li>
                </ul>
            </nav>
            <nav id="menu" className={isMenuVisible ? '' : 'hidden'}>
                <ul className="flex flex-col md:flex-row space-y-4
                md:space-y-0 md:space-x-4 no-underline w-full md:w-auto">
                    <li><a href="/" className="text-muted-foreground flex
                        flex-row
                        items-center transition-colors rounded-3xl
                        px-6
                        py-1
                        hover:bg-accent hover:text-accent-foreground
                        active
                        hover:text-blue-600 no-underline">Home</a></li>
                    <li><a href="#pricing" className="text-muted-foreground
                        flex
                        flex-row items-center transition-colors
                        rounded-3xl
                        px-6 py-1   hover:bg-accent
                        hover:text-accent-foreground active
                        hover:text-blue-600
                        no-underline">Pricing</a></li>
                    <li><a href="#contact" className="hover:text-blue-600
                        text-muted-foreground flex flex-row
                        items-center
                        transition-colors rounded-3xl px-6 py-1
                         
                        hover:bg-accent hover:text-accent-foreground
                        active
                        no-underline">Contact Us</a></li>
                </ul>
            </nav>
        </div>
    </header>
}

export default Header;